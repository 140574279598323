import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer-skeleton',
  templateUrl: './footer-skeleton.component.html',
  styleUrls: [ './footer-skeleton.component.css' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'flex footer-bottom',
  },
})
export class FooterSkeletonComponent {
  copyrightText = { width: '125px', height: '18px' };
  logos = new Array(4).fill({ width: '32px', height: '32px' });
  links = new Array(4).fill({
    text: { width: '55px', height: '17px' },
    divider: { height: '17px' },
  });
}

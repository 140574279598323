import { ActiveToast, IndividualConfig } from 'ngx-toastr';

export interface ToastConfig {
  allowManualClose: boolean; // Close toast ahead of time
  timeToLive: number; // Time to live in milliseconds
  extendedTimeToLive: number; // Time to close after a user hovers over toast
  forbidClose: boolean; // Dismiss ability to close toast
  dismissManualCloseClass: string; // Class on toast for the case when the user can't close it at all or manually
}

export interface ToastContent {
  title?: string;
  message?: string;
}

export enum ToastType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
  default = 'default',
}

export type ToastActionMap = Record<ToastType, (
  message: string,
  title: string,
  config: Partial<IndividualConfig>,
) => ActiveToast<any>>;

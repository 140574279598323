import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { MainPageComponent } from './main-page/main-page.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SubmitComponent } from './submit/submit.component';
import { RibbonPageComponent } from './ribbon-page/ribbon-page.component';
import { RibbonNoInputComponent } from './ribbon-no-input/ribbon-no-input.component';
import { CmsServicesModule, HybrisService } from '@amway/cms-services';
import { hybrisConfig } from './hybris.config';
import { CmsModuleConfigService } from './services/cms-module-config.service';
import { prepareToRenderingModule, RenderingModule } from '@amway/rendering';
import { SharedComponentsModule } from '@amway/shared-components';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { QueryParamsInterceptor } from './interceptors/query-params.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FooterSkeletonComponent } from './footer-skeleton/footer-skeleton.component';
import { ControlValidityDirective } from './directives/control-validity.directive';
import { SelectComponent } from './shared/select/select.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { CertificateSectionComponent } from './certificate-section/certificate-section.component';
import { MaskDirective } from './directives/mask.directive';
import { RadioGroupDirective } from './directives/radio-group.directive';
import { RadioDirective } from './directives/radio.directive';
import { TranslationsInterceptor } from './interceptors/translations.interceptor';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { ToastGroupedComponent } from './shared/toast-grouped/toast-grouped.component';
import { ToastService } from './shared/toast-grouped/services';
import {
  TOAST_GLOBAL_CONFIG,
  TOAST_INDIVIDUAL_CONFIG,
  TOAST_NOTIFICATION_CONFIG,
} from './shared/toast-grouped/notification.config';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    MainPageComponent,
    HeaderComponent,
    FooterComponent,
    SubmitComponent,
    RibbonPageComponent,
    RibbonNoInputComponent,
    LanguageSelectorComponent,
    FooterSkeletonComponent,
    ControlValidityDirective,
    SelectComponent,
    AlertMessageComponent,
    CertificateSectionComponent,
    MaskDirective,
    RadioGroupDirective,
    RadioDirective,
    ToastGroupedComponent
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedComponentsModule,
    ToastrModule.forRoot({
      ...TOAST_GLOBAL_CONFIG,
      toastComponent: ToastGroupedComponent,
    }),
    ToastContainerModule,
    CmsServicesModule.forRoot(hybrisConfig, HybrisService, CmsModuleConfigService),
    RenderingModule.forRoot(prepareToRenderingModule(hybrisConfig)),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueryParamsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslationsInterceptor,
      multi: true,
    },
    ToastService,
    { provide: TOAST_NOTIFICATION_CONFIG, useValue: TOAST_INDIVIDUAL_CONFIG },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { switchMap, tap, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE, LanguageLabels } from '../models/app.settings';
import { DropdownOption } from '../models/dropdown.model';
import { environment } from '../../environments/environment';
import { getLocaleByLabel } from '../models-rules/translate.models-rules';

const SELECTED_LOCALE_DATA = 'amw-selected-locale';

@Injectable({
  providedIn: 'root',
})
export class AppTranslateService {
  private storage = localStorage;
  private currentLocale$$ = new BehaviorSubject<DropdownOption>(
    {} as DropdownOption
  );
  currentLocale$: Observable<
    DropdownOption
  > = this.currentLocale$$.asObservable().pipe(distinctUntilChanged());
  translationsLoading$$ = new BehaviorSubject<boolean>(false);
  translationsLoading$ = this.translationsLoading$$.asObservable();

  constructor(private translate: TranslateService) {}

  /**
   * Sets current locale key to translate service and invokes additional callback
   * @param {string} currentLocale
   * @param {DropdownOption} appLocale
   * @param {boolean} shouldSaveToStorage - when user selects a locale so that all necessary locale data is written to storage
   * @returns {Observable<DropdownOption|any>}
   */
  setCurrentLocale(
    currentLocale: string,
    appLocale: DropdownOption,
    shouldSaveToStorage: boolean = false
  ): Observable<DropdownOption | any> {
    return this.translate.use(currentLocale).pipe(
      switchMap(() => of(appLocale)),
      tap((nextLocale: DropdownOption) =>
        this.onLocaleChanged(nextLocale, shouldSaveToStorage)
      )
    );
  }

  /**
   * Set lang from storage or use default lang when app start
   * @return {Observable<DropdownOption>}
   */
  getCurrentLocale(): Observable<DropdownOption> {
    const currentLocaleFromStorage = this.parserFromStorage(
      SELECTED_LOCALE_DATA
    );
    const currentLocale = currentLocaleFromStorage
      ? currentLocaleFromStorage
      : DEFAULT_LANGUAGE;
    this.translate.use(currentLocale.id || DEFAULT_LANGUAGE.id);
    this.currentLocale$$.next(currentLocale);
    return this.currentLocale$;
  }

  getLocale(): DropdownOption {
    const currentLocaleFromStorage: DropdownOption = this.parserFromStorage(SELECTED_LOCALE_DATA);
    const currentLocale = currentLocaleFromStorage || DEFAULT_LANGUAGE;

    return currentLocale;
  }

  getUnusedLocale(): DropdownOption {
    const siteLanding = environment.country;
    const englishIsNotSelected = this.getLocale().text !== LanguageLabels.english;

    if (englishIsNotSelected) {
      return getLocaleByLabel(LanguageLabels.english);
    }

    return siteLanding.includes('CA') ? getLocaleByLabel(LanguageLabels.french) : getLocaleByLabel(LanguageLabels.spanish);
  }

  /**
   * Used to determine URL location ex: en_US, fr_CA, etc.
   * @return {string} like en_US
   */
  getLanguageString(): string {
    const language: string = this.getLocale().text;

    let urlLocation = '';

    switch (language.toLowerCase()) {
      case 'english':
        urlLocation += 'en_';
        break;
      case 'français':
        urlLocation += 'fr_';
        break;
      case 'español':
        urlLocation += 'es_';
        break;
      default:
        urlLocation += 'en_';
        break;
    }

    urlLocation += environment.country;

    return urlLocation;
  }

  /**
   * Callback which is invoked when locale key was set to translate service.
   * @param {DropdownOption} nextLocale
   * @param {boolean} shouldSaveToStorage
   * @return {void}
   */
  private onLocaleChanged(
    nextLocale: DropdownOption,
    shouldSaveToStorage: boolean = false
  ): void {
    if (shouldSaveToStorage) {
      this.storage.setItem(SELECTED_LOCALE_DATA, JSON.stringify(nextLocale));
    }

    this.currentLocale$$.next(nextLocale);
  }

  /**
   * get object with local from storage
   * @param {string} str
   * @return {DropdownOption}
   */
  private parserFromStorage(str: string): DropdownOption | any {
    return JSON.parse(this.storage.getItem(str));
  }
}

import { Injectable } from '@angular/core';
import { DataState } from '@app/models/common.model';
import { HybrisSession } from '@app/models/hybris-api.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HybrisApiService } from './hyrbis-api.service';

@Injectable({
  providedIn: 'root',
})
export class HybrisSessionService {
  sessionData$$ = new BehaviorSubject<DataState<HybrisSession>>({ isLoading: false, data: null });

  constructor(
    private hybrisApiService: HybrisApiService,
  ) {}

  obtainSession(): Observable<HybrisSession> {
    this.sessionData$$.next({
      data: null,
      isLoading: true,
    });

    return this.hybrisApiService.obtainSession().pipe(
      tap(({ securityToken }: HybrisSession) => {
        this.sessionData$$.next({
          ...this.sessionData$$.getValue(),
          data: {
            securityToken,
          },
        });
      }),
      finalize(() => {
        this.sessionData$$.next({
          ...this.sessionData$$.getValue(),
          isLoading: false,
        });
      }),
    );
  }
}

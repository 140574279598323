import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-ribbon-no-input',
  templateUrl: './ribbon-no-input.component.html',
  styleUrls: ['./ribbon-no-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'main-container',
  },
})
export class RibbonNoInputComponent {
}

<span *ngIf="title"><img alt="alert" class="custom-icon" src="/assets/logos/alert.svg" alt="alert logo" /></span>

<ng-container *ngIf="options.closeButton; else notificationWithoutCloseButton">
  <div id="notification">
    <ng-container *ngTemplateOutlet="notification"></ng-container>
  </div>

  <!-- $event.stopPropagation() for enter must be to prevent a potential form submit -->
  <button #closeBtn type="button" class="toast-close-button" aria-describedby="notification"
    [attr.aria-label]="'common.close' | translate" (click)="close()" (keydown.enter)="$event.stopPropagation()">
    <img alt="cross" class="custom-icon" src="/assets/logos/cross.svg" alt="cross logo" />
  </button>
</ng-container>

<ng-template #notification>
  <p *ngIf="title" class="toast-title" [innerHTML]="title | translate"></p>
  <p *ngIf="message" class="toast-message" [innerHTML]="message | translate"></p>
</ng-template>

<ng-template #notificationWithoutCloseButton>
  <div role="alert" aria-live="assertive">
    <ng-container *ngTemplateOutlet="notification"></ng-container>
  </div>
</ng-template>

import { InjectionToken } from '@angular/core';

import { GlobalConfig, ToastrIconClasses } from 'ngx-toastr';

import { ToastConfig, ToastType } from './models';

const TOAST_ICON_CLASSES: ToastrIconClasses = {
  [ ToastType.error ]: 'amw-toast--error',
  [ ToastType.info ]: 'amw-toast--info',
  [ ToastType.success ]: 'amw-toast--success',
  [ ToastType.warning ]: 'amw-toast--warning',
};

/**
 * Time to live in milliseconds
 */
const TIME_TO_LIVE = 20000;

/**
 * Time to close after a user hovers over toast or close button loses focus
 */
const EXTENDED_TIME_TO_LIVE = 1000;

/**
 * Class on toast
 */
const TOAST_CLASS = 'ngx-toastr amw-toast';

/**
 * Class on toast container
 */
const POSITION_CLASS = 'toast-top-center';

/**
 * Ability to dismiss toast manually
 */
const ALLOW_MANUAL_CLOSE = true;

/**
 * Class on toast for the case when the user can't close it at all or manually
 */
const DISMISS_MANUAL_CLOSE_CLASS = 'dismiss-manual-close';

/**
 * Dismiss ability to close toast
 */
const FORBID_CLOSE = false;

/**
 * Max toasts opened
 */
const MAX_OPENED = 1;

export const TOAST_TITLE_ICON = {
  [ TOAST_ICON_CLASSES.error ]: 'amw-icon-exclamation-square',
  [ TOAST_ICON_CLASSES.info ]: 'amw-icon-info-circle',
  [ TOAST_ICON_CLASSES.success ]: 'amw-icon-check-circle',
  [ TOAST_ICON_CLASSES.warning ]: 'amw-icon-warning-light',
};

/**
 * Global toast config
 */
export const TOAST_GLOBAL_CONFIG: Partial<GlobalConfig> = {
  enableHtml: true,
  toastClass: TOAST_CLASS,
  positionClass: POSITION_CLASS,
  iconClasses: TOAST_ICON_CLASSES,
  maxOpened: MAX_OPENED,
};

/**
 * Individual toast config
 */
export const TOAST_INDIVIDUAL_CONFIG: Partial<ToastConfig> = {
  allowManualClose: ALLOW_MANUAL_CLOSE,
  timeToLive: TIME_TO_LIVE,
  extendedTimeToLive: EXTENDED_TIME_TO_LIVE,
  forbidClose: FORBID_CLOSE,
  dismissManualCloseClass: DISMISS_MANUAL_CLOSE_CLASS,
};

export const TOAST_NOTIFICATION_CONFIG = new InjectionToken<Partial<ToastConfig>>('toast.config');

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject, merge } from 'rxjs';
import { tap } from 'rxjs/operators';

let id = 0;

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit {
  @ViewChild('dropdown') dropdownElement: ElementRef<HTMLElement>;

  @Output() selectOption = new EventEmitter<string>();

  @Input() control: AbstractControl;
  @Input() options: string[] = [];
  @Input() label = '';

  private selectedOption$$ = new BehaviorSubject<string>('form.selectBtn');
  selectedOption$ = this.selectedOption$$.asObservable();
  alertMessageId: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private formGroupDirective: FormGroupDirective,
  ) {}

  @HostListener('document:keyup.escape')
  onKeyupHandler() {
    this.closeSelectOptionsBox();
  }

  @HostListener('document:keyup.tab')
  @HostListener('document:keyup.shift.tab')
  closeDropdown() {
    this.closeDropdownIfFocusedOutsideDropdownMenu();
  }

  @HostListener('focusout')
  focusOutHandler() {
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }

  @HostListener('document:click', ['$event'])
  closeAllSelect(event) {
    if (event.target.className === 'item') {
      this.onSelectOption(event);
    } else if (
      event.target.id !== 'select-button' &&
      event.target.tagName !== 'IMG'
    ) {
      this.closeSelectOptionsBox();
    }
  }

  ngOnInit(): void {
    id++;

    this.alertMessageId = `select-alert-message-${ id }`;

    merge(
      this.control.statusChanges,
      this.formGroupDirective.ngSubmit,
    )
    .pipe(
      tap(() => {
        this.addAriaDescribedbyAttribute();
        this.cdr.detectChanges();
      }),
    ).subscribe();
  }

  keydownSelect(event: KeyboardEvent): void {
    const keyCode = event.code.toLowerCase();

    if (keyCode === 'enter' || keyCode === 'space') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  keyupSelect(event: KeyboardEvent): void {
    const keyCode = event.code.toLowerCase();

    if (keyCode === 'enter' || keyCode === 'space') {
      this.clickSelect();
    }
  }

  clickSelect(): void {
    const items = document.getElementById('select-items');
    const hidden = [ ...(items.classList as any) ].some((className: string) => className.includes('hide'));
    if (hidden) {
      items.classList.remove(
        'hide'
      );

      const firstItem: HTMLDivElement = document.querySelector('.item');
      firstItem.focus();
    } else {
      items.classList.add('hide');
    }
  }

  keyupOption(event: KeyboardEvent): void {
    const keyCode = event.code.toLowerCase();

    if (!(keyCode === 'enter' || keyCode === 'space')) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.onSelectOption(event);
  }

  onSelectOption(event: Event): void {
    const choice = (event.target as any).dataset.key;
    const items = document.getElementById('select-items');
    items.classList.add('hide');
    this.selectedOption$$.next(choice);

    // check if invalid is there - if so, remove
    const btn = document.getElementById('select-button');
    btn.classList.remove('invalid-input');

    // choice has been made - mark as valid/touched
    this.control.markAsTouched();
    this.control.setValue(choice);
    this.control.updateValueAndValidity();

    this.selectOption.emit(choice);
  }

  private closeSelectOptionsBox(): void {
    const selectBox = document.getElementById('select-items');
    const hidden = [...(selectBox.classList as any)].some((className: string) => className.includes('hide'));

    if (!hidden) {
      selectBox.classList.add(
        'hide'
      );
      // mark select as dirty
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
  }

  private closeDropdownIfFocusedOutsideDropdownMenu(): void {
    const activeElement = document.activeElement as HTMLElement;

    if (activeElement.dataset.itemrole !== 'dropdownItem') {
      this.closeSelectOptionsBox();
    }
  }

  private addAriaDescribedbyAttribute(): void {
    if (!this.dropdownElement) {
      return;
    }

    this.control.invalid && this.control.touched
      ? this.dropdownElement.nativeElement.setAttribute('aria-describedby', this.alertMessageId)
      : this.dropdownElement.nativeElement.removeAttribute('aria-describedby');
  }
}

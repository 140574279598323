import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export enum PatternEnum {
  // letters, numbers and 'Space'
  regularCharacters = '^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$',
  // letters, numbers and '-'
  regularCharactersAndDash = '^[a-zA-Z0-9]*([a-zA-Z0-9]+[-]?)*[a-zA-Z0-9]$',
  // letters and numbers everywhere, 'Space', '/', '-' and '.' between words,
  regularCharactersAndSlashHyphenDot = '^[a-zA-Z0-9]*([a-zA-Z0-9]+[-/ .]?)*[a-zA-Z0-9]$',
  digits = '^[0-9]*$',
  letters = '^[a-zA-Z]*$',
}

export enum RegularCharactersValidatorName {
  onlyDigits = 'onlyDigits',
  onlyLetters = 'onlyLetters',
  regularCharacters = 'onlyRegularCharacters',
  regularCharactersAndDash = 'regularCharactersAndDash',
  regularCharactersAndSlashHyphenDot = 'regularCharactersAndSlashHyphenDot',
}

/**
 * Only letters accepted: 'a-z', 'A-Z'.
 */
export function onlyLetters(): ValidatorFn {
  const validateControl = Validators.pattern(PatternEnum.letters);

  return (control: AbstractControl): ValidationErrors | null => {
    return validateControl(control)
      ? { [ RegularCharactersValidatorName.onlyLetters ]: true }
      : null;
  };
}

/**
 * Only digits accepted: 0-9
 */
export function onlyDigits(): ValidatorFn {
  const validateControl = Validators.pattern(PatternEnum.digits);

  return (control: AbstractControl): ValidationErrors | null => {
    return validateControl(control)
      ? { [ RegularCharactersValidatorName.onlyDigits ]: true }
      : null;
  };
}

/**
 * Only regular characters and dash accepted: a-z, A-Z, 0-9, '-'
 */
export function onlyRegularCharactersAndDash(): ValidatorFn {
  const validateControl = Validators.pattern(PatternEnum.regularCharactersAndDash);

  return (control: AbstractControl): ValidationErrors | null => {
    return validateControl(control)
      ? { [ RegularCharactersValidatorName.regularCharactersAndDash ]: true }
      : null;
  };
}

/**
 * Only regular characters accepted: a-z, A-Z, 0-9, 'Space'
 */
export function onlyRegularCharacters(): ValidatorFn {
  const validateControl = Validators.pattern(PatternEnum.regularCharacters);

  return (control: AbstractControl): ValidationErrors | null => {
    return validateControl(control)
      ? { [ RegularCharactersValidatorName.regularCharacters ]: true }
      : null;
  };
}

/**
 * Only regular characters, slash, hyphen and dot accepted: a-z, A-Z, 0-9, 'Space', '/', '-', '.'
 */
export function onlyRegularCharactersAndSlashHyphenDot(): ValidatorFn {
  const validateControl = Validators.pattern(PatternEnum.regularCharactersAndSlashHyphenDot);

  return (control: AbstractControl): ValidationErrors | null => {
    return validateControl(control)
      ? { [ RegularCharactersValidatorName.regularCharactersAndSlashHyphenDot ]: true }
      : null;
  };
}

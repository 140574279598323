import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './main-page/main-page.component';
import { SubmitComponent } from './submit/submit.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { RibbonPageComponent } from './ribbon-page/ribbon-page.component';
import { RibbonNoInputComponent } from './ribbon-no-input/ribbon-no-input.component';
import { DominicanUsersForbiddenGuard } from './guards/dominican-users-forbidden.guard';
import { IboNumHomeGuard } from './guards/ibo-num-home.guard';

const routes: Routes = [
  {
    path: 'ribbon-no-input',
    component: RibbonNoInputComponent,
    canActivate: [ DominicanUsersForbiddenGuard ],
  },
  {
    path: '',
    component: AuthenticationComponent,
  },
  {
    path: 'home',
    component: MainPageComponent,
    canActivate: [ IboNumHomeGuard ],
    data: { displayIboNumInput: true },
  },
  {
    path: 'welcome',
    component: MainPageComponent,
    data: { displayIboNumInput: false },
  },
  {
    path: 'submit',
    component: SubmitComponent,
  },
  {
    path: 'ribbon',
    component: RibbonPageComponent,
    canActivate: [ DominicanUsersForbiddenGuard ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="main-container-heading">
  <h1 class="main-container-title">
    <span class="sr-only">ribbon</span>
    <img alt="Ribbon logo" src="/assets/images/ribbon-logo.jpg"/>
  </h1>

  <div class="main-container-heading-description">
    <p class="main-container-heading-description-subheading-1">
      {{ 'ribbon.description' | translate }}
    </p>
    <p class="main-container-heading-description-subheading-2">
      {{ 'form.subheading' | translate }}
    </p>
  </div>
</div>

<div toastContainer></div>

<div class="main-container-form">
  <form
    class="main-container-form-holder"
    [formGroup]="customerResponse"
    (ngSubmit)="submitForm()"
  >
    <!-- IBO NUM -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="iboNumInput">{{ 'ribbon.iboNum' | translate }} {{ 'form.optional' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          id="iboNumInput"
          class="form-control"
          formControlName="iboNum"
          [placeholder]="'form.iboNumPlaceholder' | translate"
        />
      </div>
    </div>
    <!-- FIRST NAME -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="first-name-input">{{ 'form.firstName' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #firstNameControlValidity="appControlValidity"
          id="first-name-input"
          class="form-control"
          required
          appControlValidity
          formControlName="firstName"
          ariaDescribedbyErrorId="first-name-input-alert-message"
          [placeholder]="'form.firstNamePlaceholder' | translate"
        />

        <app-alert-message
          *ngIf="firstNameControlValidity.controlError$ | async"
          [attr.id]="firstNameControlValidity.ariaDescribedbyErrorId"
          [message]="'form.firstNameError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- LAST NAME -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="last-name-input">{{ 'form.lastName' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #lastNameControlValidity="appControlValidity"
          id="last-name-input"
          class="form-control"
          required
          appControlValidity
          formControlName="lastName"
          ariaDescribedbyErrorId="last-name-input-alert-message"
          [placeholder]="'form.lastNamePlaceholder' | translate"
        />

        <app-alert-message
          *ngIf="lastNameControlValidity.controlError$ | async"
          [attr.id]="lastNameControlValidity.ariaDescribedbyErrorId"
          [message]="'form.lastNameError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- EMAIL -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for='email-address-input'>{{ 'form.emailAddr' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #emailControlValidity="appControlValidity"
          id="email-address-input"
          class="form-control"
          appControlValidity
          formControlName="email"
          type="email"
          required
          ariaDescribedbyErrorId="email-address-input-alert-message"
          [placeholder]="'form.emailAddrPlaceholder' | translate"
          (keyup)="emailInput.patchValue(emailInput.value.trim())"
        />

        <app-alert-message
          *ngIf="emailControlValidity.controlError$ | async"
          [attr.id]="emailControlValidity.ariaDescribedbyErrorId"
          [message]="'form.emailAddrError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- PHONE NUMBER -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="phone-num-input">{{ 'form.phoneNum' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #phoneControlValidity="appControlValidity"
          id="phone-num-input"
          class="form-control"
          formControlName="phoneNum"
          type="tel"
          required
          appControlValidity
          appMask
          ariaDescribedbyErrorId="phone-num-input-alert-message"
          [placeholder]="'form.phoneNumPlaceholder' | translate"
        />

        <app-alert-message
          *ngIf="(phoneControlValidity.controlError$ | async) as controlError"
          [attr.id]="phoneControlValidity.ariaDescribedbyErrorId"
          [message]="phoneErrors[ controlError ] | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- ADDRESS LINE -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="address-line-input">{{ 'form.streetAddress' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #addressLineControlValidity="appControlValidity"
          id="address-line-input"
          class="form-control"
          required
          appControlValidity
          formControlName="streetAddress"
          ariaDescribedbyErrorId="address-line-input-alert-message"
          [placeholder]="'form.streetAddress' | translate"
        />

        <app-alert-message
          *ngIf="(addressLineControlValidity.controlError$ | async) as controlError"
          [attr.id]="addressLineControlValidity.ariaDescribedbyErrorId"
          [message]="streetAddressErrors[ controlError ] | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- CITY -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="city-input">{{ 'ribbon.city' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #cityControlValidity="appControlValidity"
          id="city-input"
          class="form-control"
          required
          appControlValidity
          formControlName="city"
          ariaDescribedbyErrorId="city-input-alert-message"
          [placeholder]="placeholderValues.city"
        />

        <app-alert-message
          *ngIf="(cityControlValidity.controlError$ | async) as controlError"
          [attr.id]="cityControlValidity.ariaDescribedbyErrorId"
          [message]="cityErrors[ controlError ] | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- STATE -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="state-input">{{ stateLabelKey | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #stateControlValidity="appControlValidity"
          id="state-input"
          class="form-control"
          required
          appControlValidity
          formControlName="state"
          ariaDescribedbyErrorId="state-input-alert-message"
          [placeholder]="placeholderValues.state"
        />

        <app-alert-message
          *ngIf="(stateControlValidity.controlError$ | async) as controlError"
          [attr.id]="stateControlValidity.ariaDescribedbyErrorId"
          [message]="stateErrors[ controlError ] | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- ZIP CODE -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="zip-input">{{ zipLableKey | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          #zipControlValidity="appControlValidity"
          id="zip-input"
          class="form-control"
          appControlValidity
          required
          formControlName="zip"
          ariaDescribedbyErrorId="zip-input-alert-message"
          [placeholder]="placeholderValues.zip"
        />

        <app-alert-message
          *ngIf="(zipControlValidity.controlError$ | async) as controlError"
          [attr.id]="zipControlValidity.ariaDescribedbyErrorId"
          [message]="zipErrors[ controlError ] | translate"
        ></app-alert-message>
      </div>
    </div>
    <!-- CERTIFICATE NUMBER -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <p id="certificate-num-label" class="title-as-label">{{ 'ribbon.certificateNumber' | translate }}</p>
      </div>
      <app-certificate-section
        [certificatesFormArray]="certificatesFormArray"
        [certificateControl]="certificateControl"
      >
        <ng-template let-certificate>
          <div class="main-container-form-holder__item-input">
            <input
              #certificateControlValidity="appControlValidity"
              aria-labelledby="certificate-num-label"
              class="form-control"
              placeholder="0A0B0C0D0E0F0G"
              required
              appControlValidity
              [ariaDescribedbyErrorId]="'zip-input-alert-message-' + certificate.index"
              [formControl]="certificate.control"
            />

            <app-alert-message
              *ngIf="(certificateControlValidity.controlError$ | async) as controlError"
              [attr.id]="certificateControlValidity.ariaDescribedbyErrorId"
              [message]="certificateErrors[ controlError ] | translate"
            ></app-alert-message>
          </div>
        </ng-template>
      </app-certificate-section>
    </div>
    <!-- GIFT NAME -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="certificate-name-input">{{ 'ribbon.certificateName' | translate }} {{ 'form.optional' | translate }}</label>
      </div>
      <div class="main-container-form-holder__item-input">
        <input
          id="certificate-name-input"
          class="form-control"
          formControlName="certificateName"
          [placeholder]="'ribbon.certificateNamePlaceholder' | translate"
        />
      </div>
    </div>
    <div class="main-container-submit">
      <button
        type="submit"
        class="submit-button"
      >
        {{ 'form.submit' | translate }}
      </button>
    </div>
  </form>
</div>

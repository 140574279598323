import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { ActiveToast, IndividualConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { merge, Observable } from 'rxjs';
import { delay, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { get } from 'lodash';

import {
  documentFocusEventOutsideAngular$,
  documentKeyPressedEventOutsideAngular$,
  isElementFocusable,
  isTabKey,
} from '../../../../../utils/dom.utils';

import { ToastGroupedComponent } from '../../toast-grouped.component';
import { TOAST_NOTIFICATION_CONFIG } from '../../notification.config';
import {
  ToastActionMap,
  ToastConfig,
  ToastContent,
  ToastType,
} from './../../models';

@Injectable()
export class ToastService {
  private readonly _toastActionMap: ToastActionMap = {
    [ ToastType.info ]: (message, title, config) => this.toastrService.info(message, title, config),
    [ ToastType.success ]: (message, title, config) => this.toastrService.success(message, title, config),
    [ ToastType.warning ]: (message, title, config) => this.toastrService.warning(message, title, config),
    [ ToastType.error ]: (message, title, config) => this.toastrService.error(message, title, config),
    [ ToastType.default ]: (message, title, config) => this.toastrService.info(message, title, config),
  };
  private readonly closeBtnClass = 'toast-close-button';
  private lastFocusedElement: HTMLElement;

  constructor(
    @Inject(TOAST_NOTIFICATION_CONFIG) private config: Partial<ToastConfig>,
    @Inject(DOCUMENT) private document: Document,
    private toastrService: ToastrService,
  ) {
    /**
     * This event is important, because we have to track recent focused element
     * to be able restore the focus.
     *
     * P.S. It has been used document.activeElement within trigger method and
     * it appeared in some cases it's not enough to catch the right element.
     */
    documentFocusEventOutsideAngular$.pipe(
      tap((event: FocusEvent) => {
        const target = event.target as HTMLElement;

        if (!target.classList.contains(this.closeBtnClass)) {
          this.lastFocusedElement = target;
        }
      }),
    ).subscribe();
  }

  /**
   * Put toasts in your own container
   * https://www.npmjs.com/package/ngx-toastr
   *
   * Put toasts in a specific div inside your application.
   * This should probably be somewhere that doesn't get deleted.
   *
   * @param {ToastContainerDirective} toastContainer
   */
  setContainer(toastContainer: ToastContainerDirective): void {
    this.toastrService.overlayContainer = toastContainer;
  }

  /**
   * Show toast by type.
   *
   * @param {ToastType} type
   * @param {ToastContent} content
   * @param {Partial<ToastConfig>} config
   * @returns {ActiveToast<any>}
   */
  trigger(type: ToastType, content: ToastContent, config?: Partial<ToastConfig>): ActiveToast<any> {
    this.toastrService.clear();

    const title = get(content, 'title', '');
    const message = get(content, 'message', '');

    if (!(title || message)) {
      return null;
    }

    const fullConfig = this.extendConfig(config);
    const triggerFn = this._toastActionMap[ type ] || this._toastActionMap[ ToastType.default ];
    const activeToast = triggerFn(message, title, fullConfig);

    if (fullConfig.closeButton) {
      const toastComponent = (activeToast.toastRef.componentInstance as ToastGroupedComponent);

      merge(
        this.handleKeyPressEvent(toastComponent),
        this.handleToastClosing(activeToast),
      ).pipe(
        first(),
        tap((shouldRestoreFocus: boolean) => {
          if (shouldRestoreFocus && this.lastFocusedElement) {
            this.lastFocusedElement.focus();
          }
        }),
      ).subscribe();
    }

    return activeToast;
  }

  /**
   * @param {ToastGroupedComponent} toastComponent
   * @returns {Observable<boolean>}
   */
  private handleKeyPressEvent(toastComponent: ToastGroupedComponent): Observable<boolean> {
    return documentKeyPressedEventOutsideAngular$.pipe(
      switchMap((event: KeyboardEvent) => {
        return toastComponent.notificationCompletelyVisible$.pipe(
          first(),
          map((isNotificationVisible: boolean) => {
            const hasToRestoreFocus = isNotificationVisible && isTabKey(event) && this.isCloseBtnFocused(toastComponent);

            if (!isNotificationVisible || hasToRestoreFocus) {
              event.preventDefault();
            }

            return hasToRestoreFocus;
          }),
        );
      }),
      // only true value is needed here. anyway if notification is closed,
      // the whole stream (merge) will be closed as well, so it's not a problem,
      // if documentKeyPressedEvent$ would not emit value at all
      filter<boolean>(Boolean),
    );
  }

  /**
   * @param {ActiveToast<any>} activeToast
   * @returns {Observable<boolean>}
   */
  private handleToastClosing(activeToast: ActiveToast<any>): Observable<boolean> {
    return activeToast.onHidden.pipe(
      // delay is needed, since original toastService has to update actual currentlyActive state
      delay(0),
      map(() => this.areNotificationsNotVisible() && !isElementFocusable(this.document.activeElement as HTMLElement)),
    );
  }

  /**
   * Override toast individual config.
   *
   * @param {Partial<ToastConfig>} override
   * @returns {Partial<IndividualConfig>}
   */
  private extendConfig(override?: Partial<ToastConfig>): Partial<IndividualConfig> {
    const {
      allowManualClose: allowManualCloseByDefault,
      timeToLive: timeToLiveByDefault,
      extendedTimeToLive: extendedTimeToLiveByDefault,
      forbidClose: forbidCloseByDefault,
      dismissManualCloseClass: dismissManualCloseClassDefault,
    } = this.config;

    const classes = get(this.toastrService, 'toastrConfig.toastClass', '');
    const forbidClose = get(override, 'forbidClose', forbidCloseByDefault);
    const dismissManualCloseClass = get(override, 'dismissManualCloseClass', dismissManualCloseClassDefault);

    let allowManualClose = get(override, 'allowManualClose', allowManualCloseByDefault);
    let timeToLive = get(override, 'timeToLive', timeToLiveByDefault);
    let extendedTimeToLive = get(override, 'extendedTimeToLive', extendedTimeToLiveByDefault);
    let disableTimeOut: boolean | 'timeOut' | 'extendedTimeOut' = false;

    if (forbidClose) {
      timeToLive = 0;
      extendedTimeToLive = 0;
      allowManualClose = false;
    }

    /**
     * Since close button will recieve focus, we have to use only extendedTimeToLive,
     * which is triggered once user hovers notification or the button loses focus.
     */
    if (allowManualClose) {
      disableTimeOut = 'timeOut';
      extendedTimeToLive = timeToLive;
    }

    return {
      ...this.config,
      closeButton: allowManualClose,
      tapToDismiss: allowManualClose,
      timeOut: timeToLive,
      extendedTimeOut: extendedTimeToLive,
      toastClass: allowManualClose ? classes : `${ classes } ${ dismissManualCloseClass }`,
      disableTimeOut,
    };
  }

  /**
   * @param {ToastGroupedComponent} toastComponent
   * @returns {boolean}
   */
  private isCloseBtnFocused(toastComponent: ToastGroupedComponent): boolean {
    return this.document.activeElement === toastComponent.closeBtn.nativeElement;
  }

  /**
   * @returns {boolean}
   */
  private areNotificationsNotVisible(): boolean {
    return this.toastrService.currentlyActive === 0;
  }
}

import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import {
  AbstractCmsModuleConfigService,
  CmsDataAdapterService,
  HybrisApiConfig,
  Transformers,
  UserInfo,
} from '@amway/cms-services';
import { AppTranslateService } from './app-translate-service';
import { AUTH_CONFIG, environment } from 'environments/environment';

const anonymous = 'anonymous';

export const anonymousUserConfig = {
  userId: anonymous,
  accountId: anonymous,
};

@Injectable({
  providedIn: 'root',
})
export class CmsModuleConfigService extends AbstractCmsModuleConfigService {
  private currentLocaleIdUnderscored = '';

  constructor(
    private translateService: AppTranslateService,
  ) {
    super();

    this.translateService.currentLocale$.pipe(
      tap(() => this.currentLocaleIdUnderscored = this.translateService.getLanguageString()),
    ).subscribe();
  }

  getHybrisApiConfig(): HybrisApiConfig {
    return {
      api: `https://${ AUTH_CONFIG.host }:${ AUTH_CONFIG.port }/occ-cms/1.0.0/`,
      mediaUrl: environment.hybrisOrigin,
      siteId: environment.siteId,
      urlPrefix: environment.hybrisOrigin,
    };
  }

  getUserInfo(): UserInfo {
    return {
      accountId: anonymous,
      userId: anonymous,
    } as UserInfo;
  }

  getTransformers(): Transformers {
    return CmsDataAdapterService.iniDefaultTransformers(
      environment.hybrisOrigin,
      environment.hybrisOrigin,
      this.currentLocaleIdUnderscored,
    );
  }

  getContentstackApiConfig(): any {
    return {};
  }
}

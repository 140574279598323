<div
  #toggleBtn
  role="button"
  tabindex="0"
  aria-haspopup="true"
  aria-controls="languages-list"
  [attr.aria-expanded]="expanded$$ | async"
  class="amw-language-selector-btn"
  (click)="toggleState()"
  (keydown)="keydown($event)"
>
  <span class="amw-language-selector-btn-text">
    {{ current.text }}
  </span>
  <img
    class="amw-language-selector-btn-img"
    src="/assets/logos/Down.svg"
    alt="Arrow down"
  />
</div>
<div class="amw-language-selector-dropup-options" id="languages-list">
  <div
    *ngFor="let language of languages"
    role="button"
    tabindex="0"
    (click)="onLocaleChange(language)"
    (keydown.enter)="onLocaleChangeWithEnter(language)"
  >{{ language.text }}</div>
</div>

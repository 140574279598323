<p>
  {{ message }}
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8Z"
      stroke="#E81F3E"
      stroke-width="1.5"
    />
    <path
      d="M7 4.54004V7.99952C7 8.5518 7.44772 8.99952 8 8.99952C8.55228 8.99952 9 8.5518 9 7.99952V4.54004C9 3.98775 8.55228 3.54004 8 3.54004C7.44772 3.54004 7 3.98775 7 4.54004Z"
      fill="#E81F3E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 12C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12Z"
      fill="#E81F3E"
    />
  </svg>
</p>

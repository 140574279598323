<div class="main-container-heading">
  <h1 class="main-container-title">
    {{ 'title' | translate }}
  </h1>

  <div class="main-container-heading-description">
    <p class="main-container-heading-description-subheading-1">
      {{ 'form.description' | translate }}
    </p>
    <p class="main-container-heading-description-subheading-2">
      {{ 'form.subheading' | translate }}
    </p>
  </div>
</div>

<div toastContainer></div>

<div class="main-container-form">
  <form
    class="main-container-form-holder"
    [formGroup]="customerResponse"
    (ngSubmit)="submitForm()"
  >
    <div *ngIf="displayIboNumInput" class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="ibo-num-input">{{ 'form.iboNum' | translate }}</label>
      </div>

      <div class="main-container-form-holder__item-input">
        <input
          #iboNumValidity="appControlValidity"
          id="ibo-num-input"
          class="form-control"
          required
          formControlName="iboNum"
          appControlValidity
          ariaDescribedbyErrorId="ibo-num-input-alert-message"
          [placeholder]="'form.iboNumPlaceholder' | translate"
        />

        <app-alert-message
          *ngIf="iboNumValidity.controlError$ | async"
          [attr.id]="iboNumValidity.ariaDescribedbyErrorId"
          [message]="'form.iboNumError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="first-name-input">{{ 'form.firstName' | translate }}</label>
      </div>

      <div class="main-container-form-holder__item-input">
        <input
          #firstNameValidity="appControlValidity"
          id="first-name-input"
          class="form-control"
          required
          appControlValidity
          ariaDescribedbyErrorId="first-name-input-alert-message"
          formControlName="firstName"
          [placeholder]="'form.firstNamePlaceholder' | translate"
        />

        <app-alert-message
          *ngIf="firstNameValidity.controlError$ | async"
          [attr.id]="firstNameValidity.ariaDescribedbyErrorId"
          [message]="'form.firstNameError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="last-name-input">{{ 'form.lastName' | translate }}</label>
      </div>

      <div class="main-container-form-holder__item-input">
        <input
          #lastNameValidity="appControlValidity"
          id="last-name-input"
          class="form-control"
          required
          appControlValidity
          formControlName="lastName"
          ariaDescribedbyErrorId="last-name-input-alert-message"
          [placeholder]="'form.lastNamePlaceholder' | translate"
        />

        <app-alert-message
          *ngIf="lastNameValidity.controlError$ | async"
          [attr.id]="lastNameValidity.ariaDescribedbyErrorId"
          [message]="'form.lastNameError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="email-address-input">{{ 'form.emailAddr' | translate }}</label>
      </div>

      <div class="main-container-form-holder__item-input">
        <input
          #emailValidity="appControlValidity"
          id="email-address-input"
          class="form-control"
          required
          appControlValidity
          formControlName="email"
          type="email"
          ariaDescribedbyErrorId="email-input-alert-message"
          [placeholder]="'form.emailAddrPlaceholder' | translate"
          (input)="emailInput.patchValue(emailInput.value.trim())"
        />

        <app-alert-message
          *ngIf="emailValidity.controlError$ | async"
          [attr.id]="emailValidity.ariaDescribedbyErrorId"
          [message]="'form.emailAddrError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="phone-num-input">{{ 'form.phoneNum' | translate }} {{ 'form.optional' | translate }}</label>
      </div>

      <div class="main-container-form-holder__item-input">
        <input
          #phoneControlValidity="appControlValidity"
          id="phone-num-input"
          class="form-control"
          formControlName="phone"
          type="tel"
          appControlValidity
          appMask
          ariaDescribedbyErrorId="phone-num-input-alert-message"
          [placeholder]="'form.phoneNumPlaceholder' | translate"
        />

        <app-alert-message
          *ngIf="(phoneControlValidity.controlError$ | async) as controlError"
          [attr.id]="phoneControlValidity.ariaDescribedbyErrorId"
          [message]="phoneErrors[ controlError ] | translate"
        ></app-alert-message>
      </div>
    </div>

    <!-- Main select, show/hide different form controls (radio buttons) based on selected option -->
    <app-select
      [control]="selectControl"
      [options]="selectOptions"
      [label]="'form.whatCanWeHelpWith' | translate"
      (selectOption)="onSelectOption($event)"
    ></app-select>

    <ng-container *ngIf="(topicOptionsSectionData$ | async) as topicOptionsSectionData">
      <ng-container *ngIf="topicOptionsSectionData.topicSelected">
        <ng-container
          [ngTemplateOutlet]="topicOptionsSectionData.templateRef"
          [ngTemplateOutletContext]="{ $implicit: topicOptionsSectionData.options }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #otherTopicOptions let-options>
      <div class="main-container-form-holder__item">
        <fieldset
          #topicControlValidity="appControlValidity"
          formControlName="otherTopic"
          appRadioGroup
          appControlValidity
          ariaDescribedbyErrorId="topic-alert-message"
          class="main-container-form-holder__item-input__radio"
        >
          <legend>{{ 'form.pleaseSelect' | translate }}</legend>

          <div *ngFor="let option of options" class="main-container-form-holder__item-input__radio-item">
            <input
              appRadio
              type="radio"
              name="topic_group"
              [id]="option | translate"
              [value]="option | translate"
            />
            <label [for]="option | translate">{{
              option | translate
            }}</label>
          </div>
        </fieldset>

        <app-alert-message
          *ngIf="topicControlValidity.controlError$ | async"
          [attr.id]="topicControlValidity.ariaDescribedbyErrorId"
          [message]="'form.selectBtnError' | translate"
        ></app-alert-message>
      </div>
    </ng-template>

    <ng-template #partnerStoresTopic>
      <ng-container formGroupName="partnerStoresTopic">
        <div class="main-container-form-holder__item">
          <!-- HEADING -->
          <div class="main-container-form-holder__item-heading">
            <label for="partner-stores-name">{{ 'form.partnerStoresName' | translate }}</label>
          </div>

          <div class="main-container-form-holder__item-input">
            <input
              #partnerStoresNameControlValidity="appControlValidity"
              id="partner-stores-name"
              class="form-control"
              formControlName="storeName"
              appControlValidity
              maxlength="50"
              ariaDescribedbyErrorId="partner-stores-name-alert-message"
              [placeholder]="'form.partnerStoresNamePlaceholder' | translate"
            />
          </div>
          <app-alert-message
            *ngIf="partnerStoresNameControlValidity.controlError$ | async"
            [attr.id]="partnerStoresNameControlValidity.ariaDescribedbyErrorId"
            [message]="'form.partnerStoresNameError' | translate"
          ></app-alert-message>

          <!-- LIST OF RADIO BUTTONS -->
          <fieldset
            #requestRegardingPreviousPurchaseControlValidity="appControlValidity"
            formControlName="requestRegardingPreviousPurchase"
            appRadioGroup
            appControlValidity
            ariaDescribedbyErrorId="request-regarding-previous-purchase-alert-message"
            class="main-container-form-holder__item-input__radio"
          >
            <legend>{{ 'form.partnerStoresPrevPurchase' | translate }}</legend>

            <div class="main-container-form-holder__item-input__radio-item">
              <input
                appRadio
                type="radio"
                name="previous_purchase_group"
                id="y_radio"
                [value]="true"
              />
              <label for="y_radio">{{
                'form.partnerStoresYes' | translate
              }}</label>
            </div>
            <div class="main-container-form-holder__item-input__radio-item">
              <input
                appRadio
                type="radio"
                name="previous_purchase_group"
                id="no_radio"
                [value]="false"
              />
              <label for="no_radio">{{
                'form.partnerStoresNo' | translate
              }}</label>
            </div>
          </fieldset>

          <app-alert-message
            *ngIf="requestRegardingPreviousPurchaseControlValidity.controlError$ | async"
            [attr.id]="requestRegardingPreviousPurchaseControlValidity.ariaDescribedbyErrorId"
            [message]="'form.partnerStoresRadioError' | translate"
          ></app-alert-message>
        </div>

        <div
          *ngIf="shouldDisplayPartnerStoreAdditionalData$ | async"
          class="main-container-form-holder__item-wrapped"
        >
          <!-- addt. options for yes -->
          <div class="main-container-form-holder__item">
            <div class="main-container-form-holder__item-heading">
              <label for="sale-amt">{{ 'form.partnerStoresSaleAmt' | translate }}</label>
            </div>
            <div class="main-container-form-holder__item-input">
              <input
                #saleAmtControlValidity="appControlValidity"
                id="sale-amt"
                class="form-control sale-amount-input"
                appControlValidity
                placeholder="$0.00"
                formControlName="saleAmt"
                ariaDescribedbyErrorId="sale-amt-alert-message"
                appMask
                [mask]="'$0*.00'"
                [maskPostValueTransformer]="netSalePostValueTransformer"
                [userCanProvideNotSpecialCharsManually]="true"
                (blur)="parseValueAndUpdate()"
              />
            </div>

            <app-alert-message
              *ngIf="saleAmtControlValidity.controlError$ | async"
              [attr.id]="saleAmtControlValidity.ariaDescribedbyErrorId"
              [message]="'form.partnerStoresSaleAmtError' | translate"
            ></app-alert-message>
          </div>

          <div class="main-container-form-holder__item">
            <div class="main-container-form-holder__item-heading">
              <label for="purchase-date">{{ 'form.partnerStoresDate' | translate }}</label>
            </div>

            <div class="main-container-form-holder__item-input">
              <input
                #purchaseDateControlValidity="appControlValidity"
                type="date"
                id="purchase-date"
                class="form-control date-input"
                appControlValidity
                formControlName="purchaseDate"
                ariaDescribedbyErrorId="purchase-date-alert-message"
                [placeholder]="'form.partnerStoresDatePlaceholder' | translate"
              />
            </div>

            <app-alert-message
              *ngIf="purchaseDateControlValidity.controlError$ | async"
              [attr.id]="purchaseDateControlValidity.ariaDescribedbyErrorId"
              [message]="'form.partnerStoresDateError' | translate"
            ></app-alert-message>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <!-- end addt option -->
    <!-- END RADIO BUTTONS -->
    <div class="main-container-form-holder__item">
      <div class="main-container-form-holder__item-heading">
        <label for="detail-input">
          {{ 'form.details' | translate }}{{ detailsControl.value.length
          }}{{ 'form.details2' | translate }}
        </label>
      </div>
      <div class="main-container-form-holder__item-input">
        <textarea
          #detailsControlValidity="appControlValidity"
          id="detail-input"
          class="form-control"
          required
          appControlValidity
          formControlName="details"
          ariaDescribedbyErrorId="detail-alert-message"
          [maxlength]="10000"
        ></textarea>

        <app-alert-message
          *ngIf="detailsControlValidity.controlError$ | async"
          [attr.id]="detailsControlValidity.ariaDescribedbyErrorId"
          [message]="'form.detailsError' | translate"
        ></app-alert-message>
      </div>
    </div>
    <div class="main-container-submit">
      <button
        type="submit"
        class="submit-button"
      >
        {{ 'form.submit' | translate }}
      </button>
    </div>
  </form>
</div>

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AmwCommonModule } from '@app/services/amw.common.module';

@Injectable({
  providedIn: 'root',
})
export class IboNumHomeGuard implements CanActivate {
  constructor(private router: Router, private iboNumHolder: AmwCommonModule) {}

  canActivate(): boolean | UrlTree {
    return this.iboNumHolder.iboNumber
      ? true
      : this.router.createUrlTree(['/']);
  }
}

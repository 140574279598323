import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: [ './alert-message.component.css' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMessageComponent {
  @Input() message: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HybrisSession } from '@app/models/hybris-api.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HybrisApiService {
  constructor(
    private http: HttpClient,
  ) {}

  obtainSession(): Observable<HybrisSession> {
    return this.http.get<HybrisSession>(`${ environment.hybrisOrigin }/session`);
  }
}

import { Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appRadioGroup]',
})
export class RadioGroupDirective implements ControlValueAccessor {
  private value$$ = new BehaviorSubject<any>(null);
  value$ = this.value$$.asObservable();

  private onChange: (_: any) => void;
  private onTouched: VoidFunction;

  constructor(
    public ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
  }

  writeValue(value: any): void {
    this.value$$.next(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valueChanged(value: any): void {
    this.onChange(value);
    this.onTouched();
  }
}

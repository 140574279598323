import { OutsideClickDirective } from '@amway/shared-components';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { APP_LOCALES } from '@app/models/app.settings';
import { DropdownOption } from '@app/models/dropdown.model';
import { AppTranslateService } from '@app/services';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('toggleBtn') toggleBtn: ElementRef<HTMLElement>;

  appLocales: DropdownOption[] = APP_LOCALES;
  localeOptions: {};
  current: DropdownOption = this.translateService.getLocale();
  languages = [];
  expanded$$ = new BehaviorSubject<boolean>(false);
  private subscription = Subscription.EMPTY;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: AppTranslateService,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this.languages.push(this.current, this.translateService.getUnusedLocale());
    this.closeDropdownOnClickOutside();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  keydown(event: KeyboardEvent): void {
    switch (event.code.toLowerCase()) {
      case 'enter':
      case 'space':
        event.preventDefault();
        this.toggleState();
    }
  }

  onLocaleChangeWithEnter(language: DropdownOption): void {
    this.toggleBtn.nativeElement.focus();
    this.onLocaleChange(language);
  }

  onLocaleChange(language: DropdownOption): void {
    this.expanded$$.next(false);

    if (language.id === this.current.id) {
      return;
    }

    this.translateService.setCurrentLocale(
      typeof language.id === 'string' ? language.id : language.id.toString(),
      language,
      true
    ).pipe(take(1)).subscribe();
    this.current = language;
    this.languages.reverse();
  }

  toggleState(): void {
    this.expanded$$.next(!this.expanded$$.getValue());
  }

  private closeDropdownOnClickOutside(): void {
    this.subscription = fromEvent(this.document, 'click', { passive: true }).pipe(
      tap((event: Event) => {
        if (this.elementRef.nativeElement.contains(event.target as HTMLElement)) {
          return;
        }

        this.expanded$$.next(false);
      }),
    ).subscribe();
  }
}

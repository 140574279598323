import { AnimationEvent } from '@angular/animations';
import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import { TOAST_TITLE_ICON } from './notification.config';

@Component({
  // eslint-disable-next-line
  selector: 'amw-toast-grouped',
  templateUrl: './toast-grouped.component.html',
  styleUrls: [ './toast-grouped.component.css' ],
  // Should leave ViewEncapsulation for now
  // styles from ngx/toastr library does not applied with ng-deep
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastGroupedComponent extends Toast implements AfterViewInit, OnDestroy {
  @ViewChild('closeBtn') closeBtn: ElementRef<HTMLElement>;

  private notificationCompletelyVisible$$ = new BehaviorSubject<boolean>(false);
  notificationCompletelyVisible$ = this.notificationCompletelyVisible$$.asObservable();

  titleIconClass = TOAST_TITLE_ICON[ this.toastPackage.toastType ];

  private onCloseBtnFocus = this.stickAround.bind(this);
  private onCloseBtnBlur = this.delayedHideToast.bind(this);
  private passiveOptions = normalizePassiveListenerOptions({ passive: true });

  constructor(private toastr: ToastrService, private toastpack: ToastPackage){
    super(toastr, toastpack);
  }

  @HostListener('@flyInOut.done', [ '$event' ])
  animationCompleted(event: AnimationEvent): void {
    if (event.toState === 'active') {
      this.notificationCompletelyVisible$$.next(true);
    }
  }

  ngAfterViewInit(): void {
    if (this.closeBtn) {
      this.attachCloseBtnListeners();
    }
  }

  ngOnDestroy(): void {
    if (this.closeBtn) {
      this.detachCloseBtnListeners();
    }
  }

  close(): void {
    super.remove();
  }

  private attachCloseBtnListeners(): void {
    this.closeBtn.nativeElement.addEventListener('focus', this.onCloseBtnFocus, this.passiveOptions);
    this.closeBtn.nativeElement.addEventListener('blur', this.onCloseBtnBlur, this.passiveOptions);
  }

  private detachCloseBtnListeners(): void {
    this.closeBtn.nativeElement.removeEventListener('focus', this.onCloseBtnFocus, this.passiveOptions);
    this.closeBtn.nativeElement.removeEventListener('blur', this.onCloseBtnBlur, this.passiveOptions);
  }
}

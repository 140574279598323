import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AmwCommonModule {
  private storage = localStorage;

  constructor() {}

  setIboNum(num: any) {
    this.storage.setItem('iboNum', num);
  }

  get iboNumber() {
    return this.storage.getItem('iboNum');
  }
}

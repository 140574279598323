<h1 class="contact-us-title">
  {{ 'title' | translate }}
</h1>

<div class="amwayid-form-area">
  <div class="amwayid-form-area-item">
    <label class="amwayid-form-area-item__text" for="iboNumInput">{{ 'login.iboNumPrompt' | translate }}</label>
  </div>

  <div class="amwayid-form-area-item">
    <form
      class="amwayid-form-area-item__input"
      id="ibo-num-form"
      (ngSubmit)="goToNextPage()"
    >
      <input
        #iboNumValidity="appControlValidity"
        appControlValidity
        type="text"
        required
        id="iboNumInput"
        autocomplete="username"
        class="form-control"
        [formControl]="userNumberControl"
        placeholder="012345678"
      />

      <app-alert-message
        *ngIf="iboNumValidity.controlError$ | async"
        class="centered-text"
        id="ibo-num-input-alert-message"
        [message]="'form.iboCustomerNumError' | translate"
      ></app-alert-message>
    </form>
  </div>

  <div class="amwayid-form-area-item">
    <button
      type="submit"
      class="amwayid-form-area-item__button submit-button"
      [attr.form]="'ibo-num-form'"
    >
      {{ 'login.continueButton' | translate }}
    </button>
  </div>
</div>

<div class="unregistered-area">
  <p>{{ 'login.unregisteredUser' | translate }}</p>
  <span>&nbsp;<a [routerLink]="['/welcome']">{{ 'login.guestLink' | translate }}</a></span>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AmwCommonModule } from '../services/amw.common.module';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'main-container',
  },
})
export class AuthenticationComponent {
  userNumberControl = new FormControl('', [ Validators.required, Validators.pattern('[0-9]+') ]);

  constructor(private router: Router, private iboNumTracker: AmwCommonModule) {}

  goToNextPage(): void {
    if (!this.userNumberControl.valid) {
      this.userNumberControl.markAsTouched();

      return;
    }

    this.iboNumTracker.setIboNum(this.userNumberControl.value);
    this.router.navigate([ '/home' ]);
  }
}

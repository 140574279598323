import { HybrisElement, Transformers } from '@amway/cms-services';
import * as components from '@amway/shared-components';
import { FooterComponent } from './footer/footer.component';

interface PageRawData {
  template: string;
  typeCode: string;
  title?: string;
  contentSlots?: any[];
  breadcrumbs?: any[];
}

interface ComponentSet {
  component: unknown;
  adapter: (rawData: Partial<HybrisElement | PageRawData>, adapter?: (...args: any[]) => any, transformers?: Transformers) => {};
}

interface ComponentCmsConfig {
  [ componentCode: string ]: ComponentSet;
}

export function defaultHybrisAdapter(rawData: any, adapter: any, transformers: Transformers): any {
  const slotCollection = rawData?.contentSlots?.contentSlot ?? [];

  return {
    uid: rawData.uid,
    typeCode: undefined,
    slots: slotCollection.reduce((slots: any, slot: any) => {
      const slotComponents = slot?.components?.component ?? [];

      slots[ slot.slotId ] = slotComponents.map((component: any) =>
        adapter(component.typeCode, component, transformers),
      );

      return slots;
    }, {}),
  };
}

const pageCollection: ComponentCmsConfig = {
  businessCenterHeaderFooterPage: {
    component: FooterComponent,
    adapter: defaultHybrisAdapter,
  },
};

const componentCollection: ComponentCmsConfig = {
  LynxTopFooterLinkComponentContainer: {
    component: components.FooterNavigationComponent,
    adapter: components.footerNavigationHybrisAdapter,
  },
  LynxFooterLinkComponentContainer: {
    component: components.FooterCopyrightsContainerComponent,
    adapter: components.footerCopyrightsContainerHybrisAdapter,
  },
  LynxFooterLinkComponent: {
    component: components.LinkComponent,
    adapter: components.linkHybrisAdapter,
  },
  CMSLinkComponent: {
    component: components.LinkComponent,
    adapter: components.linkHybrisAdapter,
  },
  LynxFooterRepresentationComponentContainer: {
    component: components.FooterCertificationContainerComponent,
    adapter: components.footerLogosContainerHybrisAdapter,
  },
  LynxFooterRepresentationComponent: {
    component: components.ImageComponent,
    adapter: components.ImageHybrisAdapter,
  },
  LynxCMSLinkComponent: {
    component: components.LinkComponent,
    adapter: components.linkButtonHybrisAdapter,
  },
  LynxSiteLogoBannerComponent: {
    component: components.HeaderLogoComponent,
    adapter: components.headerLogoHybrisAdapter,
  },
};

export const hybrisConfig: ComponentCmsConfig = {
  ...pageCollection,
  ...componentCollection,
};

<h1 class="main-container-title">
  <span class="sr-only">ribbon</span>
  <img src="/assets/images/ribbon-logo.jpg" alt="ribbon logo"/>
</h1>

<p class="main-container-message">
  {{ 'ribbon.noInputMessage1' | translate }}
  <a [routerLink]="['/ribbon']">{{ 'ribbon.noInputLink' | translate }}</a>
  {{ 'ribbon.noInputMessage2' | translate }}
</p>

import { environment, EMAIL_CONFIG } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import { AppTranslateService } from './app-translate-service';
import * as frContent from '../../assets/i18n/fr.json';
import * as esContent from '../../assets/i18n/es.json';
import * as enContent from '../../assets/i18n/en.json';
import { catchError, last, map } from 'rxjs/operators';
import { EmailBody } from '@app/models/email-body.model';
import { EmailSendRequest } from '@app/models/email-send-request.model';
import { LanguageIds } from '@app/models/app.settings';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root',
})
export class AppEmailService {
  message: EmailSendRequest;
  messageBody: string;
  iboNum: string;
  subject = 'Webform: ';
  from: string;

  constructor(
    private http: HttpClient,
    private translater: AppTranslateService
  ) {}

  inputContent(rawContent: EmailBody) {
    this.formulateBody(rawContent);
    this.formulateEmail();
  }

  formulateEmail(): void {
    this.message = {
      country: environment.country,
      localeId: this.translater.getLocale().id as LanguageIds ?? LanguageIds.en,
      message: {
        body: this.messageBody,
        subject: this.subject,
      },
      replyTo: this.from.toLowerCase(),
      sendTo: environment.production
        ? EMAIL_CONFIG.to.toLowerCase()
        : this.from.toLowerCase(),
    };

    if (this.iboNum) {
      this.message.iboNumber = this.iboNum;
    }
  }

  formulateBody(content: EmailBody): void {
    this.messageBody = '';
    let translations;
    const language = this.translater.getLocale().text;
    if (language === 'English') {
      translations = enContent;
    } else if (language === 'Español') {
      translations = esContent;
    } else if (language === 'Français') {
      translations = frContent;
    }

    let busNat = '';

    if (content.iboNum) {
      busNat += `<span><b>${translations.default.form.bussinessNature}: </b>${translations.default.form.loggedIn}</span><br>`;
      this.messageBody += `<span><b>${translations.default.form.iboNum}: </b>${content.iboNum}</span><br>`;
      this.iboNum = content.iboNum.toString();
    } else {
      busNat += `<span><b>${translations.default.form.bussinessNature}: </b>${translations.default.form.loggedOut}</span><br>`;
      this.iboNum = '';
    }
    this.messageBody +=
      `<span><b>${translations.default.form.firstName}: </b>${content.firstName}</span><br>` +
      `<span><b>${translations.default.form.lastName}: </b>${content.lastName}</span><br>` +
      `<span><b>${translations.default.form.emailAddr}: </b>${content.email}</span><br>`;
    this.from = content.email;
    if (content.phoneNum) {
      this.messageBody += `<span><b>${translations.default.form.phoneNum}: </b>${content.phoneNum}</span><br>`;
    }

    if (content.streetAddress) {
      this.messageBody += `<span><b>${translations.default.form.streetAddress}: </b>${content.streetAddress}</span><br>`;
    }

    if (content.certificateNumbers !== undefined) {
      this.messageBody +=
        `<span><b>${translations.default.ribbon.city}: </b>${content.city}</span><br>` +
        `<span><b>${translations.default.ribbon.state}: </b>${content.state}</span><br>` +
        `<span><b>${translations.default.ribbon.zip}: </b>${content.zip}</span><br>` +
        `<span><b>${
          translations.default.ribbon.certificateNumber
        }: </b>${content.certificateNumbers.join(', ')}</span><br>`;
      if (content.certificateName !== undefined) {
        this.messageBody += `<span><b>${translations.default.ribbon.certificateName}: </b>${content.certificateName}</span><br>`;
      }

      this.subject += `Ribbon Redemption`;
    } else {
      this.messageBody += busNat;
      this.messageBody += `<br>`;
      this.subject += `${content.topic}`;
      if (content.issue) {
        this.subject += ` ${content.issue}`;
        this.messageBody += `<span>${content.topic} ${content.issue}<span><br>`;
        this.messageBody += `<br>`;
      } else {
        this.messageBody += `<span>${content.topic}<span><br>`;
        this.messageBody += `<br>`;
      }
      if (content.partnerStore) {
        this.messageBody +=
          `<span><b>${translations.default.form.partnerStoresName}: </b>${content.partnerStore}</span><br>` +
          `<span><b>${translations.default.form.partnerStoresPrevPurchase}: </b>${content.previousPurchase}</span><br>`;
        if (content.purchaseDate && content.saleAmount) {
          const x = new Date(content.purchaseDate);
          const newDate = `${
            x.getMonth() + 1
          }/${x.getDate()}/${x.getFullYear()}`;
          this.messageBody +=
            `<span><b>${translations.default.form.partnerStoresSaleAmt}: </b>${content.saleAmount}</span><br>` +
            `<span><b>${translations.default.form.partnerStoresDate}: </b>${newDate}</span><br>`;
        }
      }
      // NO PREFIX REQUIRED FOR BODY
      this.messageBody += `<span><b>${translations.default.form.detailWord}: </b>${content.details}</span><br>`;
    }
  }

  sendEmail() {
    const req = new HttpRequest('POST', '/send-email', this.message);

    return this.http.request(req).pipe(
      map((event) => this.getEventMessageEmail(event)),
      last(),  // return last (completed) message to caller
      catchError(() => {
        return of(null);
      })
    );
  }

  clearMessage() {
    this.subject = 'Webform: ';
    this.messageBody = '';
  }

  private getEventMessageEmail(event: HttpEvent<any>, file?: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Request successfully sent.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return `File is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return 200;

      default:
        return `Event: ${event.type}.`;
    }
  }
}

import { CmsDataService } from '@amway/cms-services';
import { Injectable } from '@angular/core';
import { DataState } from '@app/models/common.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HybrisCmsDataService {
  pageData$$ = new BehaviorSubject<DataState<any>>({ isLoading: false, data: null });
  private readonly pageId = 'businessCenterHeaderFooterPage';

  constructor(
    private cmsDataService: CmsDataService,
  ) {}

  obtainData(): Observable<any> {
    this.pageData$$.next({
      isLoading: true,
      data: null,
    });

    return this.cmsDataService.getPage(this.pageId).pipe(
      tap((data: any) => {
        this.pageData$$.next({
          ...this.pageData$$.getValue(),
          data,
        });
      }),
      finalize(() => {
        this.pageData$$.next({
          ...this.pageData$$.getValue(),
          isLoading: false,
        });
      }),
    );
  }
}

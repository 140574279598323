import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppTranslateService } from '../services/app-translate-service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'main-container',
  },
})
export class SubmitComponent implements OnInit {
  workingHoursSections: string[];

  constructor(private translateService: AppTranslateService) {}

  ngOnInit(): void {
    // check for Atlantic Standard Time Region for DO
    this.workingHoursSections = environment.country === 'DO'
      ? [ 'submit.hours1_ast', 'submit.hours2_ast' ]
      : [ 'submit.hours1' ];
  }

  redirectToHelpCenter(): void {
    const locat: string = this.translateService.getLanguageString();

    const url = `${ environment.hybrisOrigin }/${ locat }/support-center`;

    // Finalized URL - reroute
    location.href = url;
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DominicanUsersForbiddenGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}

  canActivate(): boolean | UrlTree {
    return environment.country === 'DO' ? this.router.createUrlTree([ '/' ]) : true;
  }
}

import { switchMap, take, tap } from 'rxjs/operators';
import { Component, ElementRef, OnInit } from '@angular/core';
import { AppTranslateService } from '@app/services';
import { HybrisSessionService } from './services/hybris-session.service';
import { HybrisCmsDataService } from './services/hybris-cms-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  footerData$ = this.hybrisCmsDataService.pageData$$.asObservable();
  translationsLoading$ = this.translateService.translationsLoading$.pipe(
    tap((loading: boolean) => this.elementRef.nativeElement.classList.toggle('loading', loading)),
  );

  constructor(
    private translateService: AppTranslateService,
    private hybrisSessionService: HybrisSessionService,
    private hybrisCmsDataService: HybrisCmsDataService,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this.translateService.getCurrentLocale().pipe(take(1)).subscribe();
    this.hybrisSessionService.obtainSession().subscribe();

    this.translateService.currentLocale$.pipe(
      switchMap(() => this.hybrisCmsDataService.obtainData()),
    ).subscribe();
  }
}

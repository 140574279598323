<div
  *ngFor="let certificateItemForView of certificateItemsForView; let i = index; trackBy: trackById"
  #certificateContainer
  class="certificate-container"
  [@certificateItemAppearance]="certificateItemForView.animationState"
  [@.disabled]="!certificateItemForView.displayDeleteButton"
  (@certificateItemAppearance.done)="animationCompleted($event, i)"
>
  <div class="certificate-container--input" [class.short-input]="certificateItemForView.shortInputContainer">
    <ng-container
      [ngTemplateOutlet]="certificateTemplate"
      [ngTemplateOutletContext]="{ $implicit: { control: certificateItemForView.control, index: i } }"
    ></ng-container>
  </div>
  <div class="certificate-container--btn">
    <button
      *ngIf="certificateItemForView.displayDeleteButton"
      #removeCodeButton
      type="button"
      [attr.aria-label]="'ribbon.removeCode' | translate"
      (click)="removeCertificate(i, certificateContainer, removeCodeButton)"
    >
      <img aria-hidden="true" src="/assets/images/trash.svg" alt="delete icon">
    </button>
  </div>
</div>

<div class="add-certificate">
  <button
    type="button"
    [disabled]="addCertificateDisabled"
    (click)="addCertificate()"
  >
    <img aria-hidden="true" src="/assets/images/plus.svg" alt="add more icon">&nbsp;
    {{ 'ribbon.addAnotherCode' | translate }}
  </button>
</div>

import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { RadioGroupDirective } from './radio-group.directive';

@Directive({
  selector: '[appRadio]',
})
export class RadioDirective implements OnInit, OnDestroy {
  private subscription = Subscription.EMPTY;

  constructor(
    private radioGroupDirective: RadioGroupDirective,
    private elementRef: ElementRef<HTMLInputElement>,
  ) {}

  ngOnInit(): void {
    this.subscription = merge(
      this.radioGroupDirective.ngControl.control.valueChanges,
      this.radioGroupDirective.value$,
    ).pipe(
      tap((value: any) => {
        if (value === this.elementRef.nativeElement.value && !this.elementRef.nativeElement.checked) {
          this.elementRef.nativeElement.checked = true;
        }

        if (value !== this.elementRef.nativeElement.value && this.elementRef.nativeElement.checked) {
          this.elementRef.nativeElement.checked = false;
        }
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click')
  @HostListener('focus')
  onChange(): void {
    this.radioGroupDirective.valueChanged(this.elementRef.nativeElement.value);
  }
}

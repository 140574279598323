import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

enum AnimationState {
  added = 'added',
  removed = 'removed',
}

interface CertificateItemForView {
  control: FormControl;
  id: number;
  shortInputContainer: boolean;
  displayDeleteButton: boolean;
  animationState: AnimationState;
}

const certificateItemAnimation = trigger('certificateItemAppearance', [
  state(`${ AnimationState.removed }, void`, style({ height: '0px', opacity: 0 })),
  state(AnimationState.added, style({ height: '*', opacity: 1 })),
  transition(
    `${ AnimationState.added } <=> ${ AnimationState.removed }, void => ${ AnimationState.added }`,
    // keep the animation time in sync with css file
    animate('.3s ease'),
  ),
]);

@Component({
  selector: 'app-certificate-section',
  templateUrl: './certificate-section.component.html',
  styleUrls: [ './certificate-section.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ certificateItemAnimation ],
})
export class CertificateSectionComponent implements OnInit {
  @ContentChild(TemplateRef) certificateTemplate: TemplateRef<any>;
  @Input() certificatesFormArray: FormArray;
  @Input() certificateControl: () => FormControl;

  certificateIdCounter = 0;

  certificateItemsForView: CertificateItemForView[];

  addCertificateDisabled: boolean;
  minCertificatesToDisplay = 1;

  private maxCertificatesToDisplay = 25;

  ngOnInit(): void {
    this.setMinimalCertificatesControls();
  }

  trackById(_: number, certificateItem: CertificateItemForView): number {
    return certificateItem.id;
  }

  animationCompleted(event: AnimationEvent, index: number): void {
    if (event.toState === AnimationState.removed) {
      this.certificatesFormArray.removeAt(index);
      this.certificateItemsForView.splice(index, 1);
      this.checkAddCertificateButtonAvailability();

      if (this.certificateItemsForView.length === this.minCertificatesToDisplay) {
        for (let i = 0; i < this.minCertificatesToDisplay; i++) {
          this.certificateItemsForView[ i ].shortInputContainer = false;
        }
      }
    }
  }

  removeCertificate(index: number, certificateContainer: HTMLElement, removedCodeButton: HTMLElement): void {
    this.certificateItemsForView[ index ].animationState = AnimationState.removed;

    const previousCodeInput = certificateContainer.previousElementSibling.querySelector('input');
    const removedCodeInput = certificateContainer.querySelector('input');

    // to prevent such elements (that will be deleted once animation is completed) from being focused
    [ removedCodeButton, removedCodeInput ].forEach((element: HTMLElement) => {
      element.setAttribute('tabindex', '-1');
      element.style.pointerEvents = 'none';
    });

    previousCodeInput.focus();
  }

  addCertificate(): void {
    const control = this.certificateControl();

    this.certificatesFormArray.push(control);

    const displayDeleteButton = this.certificatesFormArray.length > this.minCertificatesToDisplay;

    this.certificateItemsForView.push({
      control,
      id: this.certificateIdCounter++,
      displayDeleteButton,
      shortInputContainer: displayDeleteButton,
      animationState: AnimationState.added,
    });
    this.checkAddCertificateButtonAvailability();

    if (this.certificateItemsForView.length > this.minCertificatesToDisplay) {
      for (let i = 0; i < this.minCertificatesToDisplay; i++) {
        this.certificateItemsForView[ i ].shortInputContainer = true;
      }
    }
  }

  private setMinimalCertificatesControls(): void {
    for (let i = 0; i < this.minCertificatesToDisplay; i++) {
      this.certificatesFormArray.push(this.certificateControl());
    }

    this.checkAddCertificateButtonAvailability();
    this.mapCertificatesDataForView();
  }

  private checkAddCertificateButtonAvailability(): void {
    this.addCertificateDisabled = this.certificatesFormArray.controls.length >= this.maxCertificatesToDisplay;
  }

  private mapCertificatesDataForView(): void {
    this.certificateItemsForView = this.certificatesFormArray.controls.map((control: FormControl, index: number) => ({
      control,
      id: this.certificateIdCounter++,
      displayDeleteButton: index + 1 > this.minCertificatesToDisplay,
      shortInputContainer: this.certificatesFormArray.length > this.minCertificatesToDisplay,
      animationState: AnimationState.added,
    }));
  }
}

import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appMask]',
})
export class MaskDirective implements OnInit {
  @Input() mask = '000-000-0000';
  @Input() userCanProvideNotSpecialCharsManually = false;
  @Input() maskPattern: { [ key: string ]: any; };
  @Input() maskPostValueTransformer: (value: string) => string;

  private control: FormControl;
  private singleCharSelected: boolean;
  private notSpecialMaskCharsSet = new Set();
  private previousCharIsNotSpecial: boolean;
  private nextCharIsNotSpecial: boolean;

  private specialMaskCharsSet = new Set([ '0', '*' ]);
  private specialMaskCharRegExps = {
    0: /[0-9]/,
  };

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private ngControl: NgControl,
  ) {}

  ngOnInit(): void {
    if (this.maskPattern) {
      Object.keys(this.maskPattern).forEach((specialMaskCharPatternKey: string) => {
        this.specialMaskCharsSet.add(specialMaskCharPatternKey);
        this.specialMaskCharRegExps[ specialMaskCharPatternKey ] = this.maskPattern[ specialMaskCharPatternKey ];
      });
    }

    this.control = this.ngControl.control as FormControl;
    this.findAllNotSpecialMaskChars();
  }

  @HostListener('input', [ '$event' ])
  onChange(event: InputEvent): void {
    // selectionStart must be taken before input's value is updated, to have an actual selectionStart value
    const { selectionStart } = this.elementRef.nativeElement;
    let inputChars = this.elementRef.nativeElement.value.split('');
    const carretShift = this.getCarretShift(event, inputChars);

    if (!this.userCanProvideNotSpecialCharsManually) {
      inputChars = inputChars.filter((char: string) => !this.notSpecialMaskCharsSet.has(char));
    }

    this.control.setValue(this.applyCharsAccordingToMask(event, inputChars));

    this.updateCarretPosition(event, carretShift, selectionStart);
  }

  @HostListener('keydown', [ '$event' ])
  onKeydown(event: KeyboardEvent) {
    const element = this.elementRef.nativeElement;
    const { value, selectionStart } = element;

    this.previousCharIsNotSpecial = this.notSpecialMaskCharsSet.has(value[ selectionStart - 1 ]);
    this.nextCharIsNotSpecial = this.notSpecialMaskCharsSet.has(this.mask[ selectionStart ]);
    this.singleCharSelected = element.selectionStart === element.selectionEnd;

    if (
      event.code === 'Backspace'
      || event.code === 'ArrowLeft'
      || event.code === 'ArrowRight'
      || event.code === 'Tab'
      || event.metaKey
      || event.altKey
      || event.shiftKey
      || event.ctrlKey
      || this.notSpecialMaskCharsSet.has(event.key)
      || this.checkIfPressedCharMatchesAnySpecialMaskChar(event)
    ) {
      return;
    }

    event.preventDefault();
  }

  private updateCarretPosition(event: InputEvent, carretShift: number, selectionStart: number): void {
    if (
      event.inputType === 'deleteContentBackward'
      || (
        event.inputType === 'insertText'
        && (!this.singleCharSelected || selectionStart !== this.elementRef.nativeElement.value.length)
      )
    ) {
      const carretPosition = selectionStart + carretShift;

      this.elementRef.nativeElement.setSelectionRange(carretPosition, carretPosition);
    }
  }

  private findAllNotSpecialMaskChars(): void {
    for (let i = 0, maskChar: string; i < this.mask.length; i++) {
      maskChar = this.mask[ i ];

      if (!this.specialMaskCharsSet.has(maskChar)) {
        this.notSpecialMaskCharsSet.add(maskChar);
      }
    }
  }

  private checkIfPressedCharMatchesAnySpecialMaskChar(event: KeyboardEvent): boolean {
    return [ ...this.specialMaskCharsSet.values() ].some((specialMaskChar: string) => {
      const specialCharRegExp: RegExp = this.specialMaskCharRegExps[ specialMaskChar ];

      return specialCharRegExp?.test(event.key);
    });
  }

  private getCarretShift(event: InputEvent, inputChars: string[]): number {
    const controlValue: string = this.control.value;
    const { selectionStart } = this.elementRef.nativeElement;
    let carretShift = 0;

    if (event.inputType === 'deleteContentBackward' && this.previousCharIsNotSpecial) {
      for (let i = selectionStart - 1; controlValue.length >= i; i--) {
        carretShift--;

        if (!this.notSpecialMaskCharsSet.has(controlValue[ i ])) {
          inputChars.splice(i, 1);

          return carretShift;
        }
      }
    }

    if (event.inputType === 'insertText' && this.nextCharIsNotSpecial) {
      for (let i = selectionStart; i < this.mask.length; i++) {
        carretShift++;

        if (!this.notSpecialMaskCharsSet.has(this.mask[ i ])) {
          return carretShift;
        }
      }
    }

    return carretShift;
  }

  private applyCharsAccordingToMask(event: InputEvent, inputChars: string[]): string {
    let valueAccordigToTheMask = '';

    for (let i = 0, j = 0; i < inputChars.length; i++, j++) {
      const maskChar = this.mask[ j ];
      const realChar = inputChars[ i ];

      if (this.notSpecialMaskCharsSet.has(maskChar)) {
        i--;
        valueAccordigToTheMask += maskChar;

        continue;
      }

      if (!this.specialMaskCharsSet.has(maskChar)) {
        continue;
      }

      if (maskChar === '*') {
        const prevSpecialCharRegExp: RegExp = this.specialMaskCharRegExps[ this.mask[ j - 1 ] ];

        if (prevSpecialCharRegExp.test(realChar)) {
          valueAccordigToTheMask += realChar;

          if (!this.notSpecialMaskCharsSet.has(inputChars[ i + 1 ])) {
            j--;
          }
        } else if (this.notSpecialMaskCharsSet.has(realChar)) {
          i--;
        }

        continue;
      }

      const specialCharRegExp: RegExp = this.specialMaskCharRegExps[ maskChar ];

      if (specialCharRegExp.test(realChar)) {
        valueAccordigToTheMask += realChar;
      } else {
        j--;
      }
    }

    if (
      valueAccordigToTheMask.length === 1
      && this.notSpecialMaskCharsSet.has(valueAccordigToTheMask[ 0 ])
      && (event.inputType === 'deleteContentBackward' || !this.singleCharSelected)
    ) {
      valueAccordigToTheMask = '';
    }

    return !!this.maskPostValueTransformer
      ? this.maskPostValueTransformer(valueAccordigToTheMask)
      : valueAccordigToTheMask;
  }
}

import { MonoTypeOperatorFunction, Observable, Subscriber } from 'rxjs';
import { HasEventTargetAddRemove } from 'rxjs/internal/observable/fromEvent';
import { tap } from 'rxjs/operators';

/**
 * Outputs value next to the optional label to the console.
 * @param {string} label
 * @returns {MonoTypeOperatorFunction<T>}
 */
export function debug<T>(label?: string): MonoTypeOperatorFunction<T> {
  // eslint-disable-next-line no-console
  return (source: Observable<T>) => source.pipe(tap(value => console.log(label, value)));
}

declare let __zone_symbol__addEventListener: any;
declare let __zone_symbol__removeEventListener: any;

/**
 * @param {FromEventTarget<M>} target
 * @param {T} type
 * @param {boolean | AddEventListenerOptions} [options]
 * @returns {Observable<DocumentEventMap[ T ]>}
 */
export function fromEventOutsideZone<M, T extends keyof DocumentEventMap = any>(
  target: HasEventTargetAddRemove<M>,
  type: T,
  options?: boolean | AddEventListenerOptions,
): Observable<DocumentEventMap[ T ]> {
  return new Observable((observer: Subscriber<DocumentEventMap[ T ]>) => {
    const callback = (event: DocumentEventMap[ T ]) => observer.next(event);

    __zone_symbol__addEventListener.call(target, type, callback, options);

    return {
      unsubscribe: () => {
        __zone_symbol__removeEventListener.call(target, type, callback, options);
      },
    };
  });
}

/**
 * setTimeout prevents stream from finalizing. 
 * 
 * @param {any} err
 */
export function throwErrorSafely(err: any): void {
  setTimeout(() => {
    throw err;
  });
}

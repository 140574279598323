<app-header></app-header>

<main class="amw-content">
  <amw-loader
    *ngIf="translationsLoading$ | async"
  ></amw-loader>

  <router-outlet></router-outlet>
</main>

<footer *ngIf="(footerData$ | async) as footerData">
  <app-footer-skeleton *ngIf="footerData.isLoading; else footer"></app-footer-skeleton>

  <ng-template #footer>
    <ng-container [amwayRenderOutlet]="footerData.data"></ng-container>
  </ng-template>
</footer>

<header *ngIf="{ translationsLoading: translationsLoading$ | async } as pageData" class="container">
  <div class="container-section">
    <div *ngIf="pageData.translationsLoading; else backLink" class="back-link skeleton-loader"></div>

    <ng-template #backLink>
      <amer-link
        [url]="backButtonUrl$ | async"
        [text]="'back-to' | translate : { site: siteName }"
      >
        <img
          class="amer-link-arrow"
          src="../../assets/logos/Down.svg"
          alt="Back arrow"
        />
      </amer-link>
    </ng-template>
  </div>
  <div class="container-section">
    <div class="amw-header-logo">
      <ng-container *ngIf="(siteLogoSlot$ | async) as siteLogoSlot">
        <div *ngIf="siteLogoSlot.isLoading; else siteLogo" class="amer-header-logo skeleton-loader"></div>

        <ng-template #siteLogo>
          <ng-container [amwayRenderOutlet]="siteLogoSlot.data"></ng-container>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="container-section flex">
    <div *ngIf="pageData.translationsLoading; else languageDropdown" class="language-dropdown skeleton-loader"></div>

    <ng-template #languageDropdown>
      <app-language-selector></app-language-selector>
    </ng-template>
  </div>
</header>

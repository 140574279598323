<div class="main-container-form-holder__item">
  <p class="main-container-form-holder__item-heading">{{ label }}</p>

  <div class="dropdown-container">
    <div
      #dropdown
      tabindex="0"
      [attr.aria-label]="label"
      role="listbox"
      class="main-container-form-holder__item-input-arrow form-control"
      id="select-button"
      [ngClass]="{
        'invalid-input': control.invalid && control.touched
      }"
      (click)="clickSelect()"
      (keyup)="keyupSelect($event)"
      (keydown)="keydownSelect($event)"
    >
      <p class="btn-text__left" id="select_text">
        {{ selectedOption$ | async | translate }}
      </p>
      <p class="btn-text__right" id="select_arrow">
        <img alt="Arrow down" src="/assets/logos/Down.svg" alt="arrow logo"/>
      </p>
    </div>

    <div
      class="main-container-form-holder__item-input-items hide"
      id="select-items"
      role="group"
    >
      <ng-container *ngFor="let option of options; last as isLast">
        <div
          tabindex="0"
          class="item"
          [id]="option"
          [attr.data-key]="option"
          [attr.data-itemrole]="'dropdownItem'"
          (keyup)="keyupOption($event)"
          (keydown)="keydownSelect($event)"
        >
          {{ option | translate }}
        </div>
        <div *ngIf="!isLast" class="divider">
          <hr />
        </div>
      </ng-container>
    </div>
  </div>

  <app-alert-message
    *ngIf="control.invalid && control.touched"
    [attr.id]="alertMessageId"
    [message]="'form.selectBtnError' | translate"
  ></app-alert-message>
</div>

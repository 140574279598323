import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppTranslateService } from '@app/services';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class TranslationsInterceptor implements HttpInterceptor {
  constructor(
    private appTranslateService: AppTranslateService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes('i18n')) {
      this.appTranslateService.translationsLoading$$.next(true);

      return next.handle(req).pipe(
        finalize(() => {
          this.appTranslateService.translationsLoading$$.next(false);
        }),
      );
    }

    return next.handle(req);
  }
}

import { DropdownOption } from './dropdown.model';

export const appLocaleConfig = {
  assets: {
    prefix: './assets/i18n/',
    suffix: '.json',
  },
};

export enum LanguageLabels {
  english = 'English',
  spanish = 'Español',
  french = 'Français',
  usa = 'United States',
}

export enum LanguageIds {
  en = 'en',
  es = 'es',
  fr = 'fr',
  us = 'US',
}

export const APP_LOCALES = [
  { text: LanguageLabels.english, id: LanguageIds.en },
  { text: LanguageLabels.spanish, id: LanguageIds.es },
  { text: LanguageLabels.french, id: LanguageIds.fr },
];

export const DEFAULT_LANGUAGE: DropdownOption = {
  text: LanguageLabels.english,
  id: LanguageIds.en,
};

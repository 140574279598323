import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppEmailService } from '../services';
import { AmwCommonModule } from '../services/amw.common.module';
import { EmailBody } from '../models/email-body.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastContainerDirective } from 'ngx-toastr';
import { ToastService } from '@app/shared/toast-grouped/services';
import { ToastType } from '@app/shared/toast-grouped/models';

export function validateDateControl(control: AbstractControl): ValidationErrors | null {
  const saleDate: Date = new Date(control.value);

  return saleDate.getFullYear() > 9999 ? { incorrect: true } : null;
}

export function validateSaleAmtControl(control: AbstractControl): ValidationErrors | null {
  const value: string = control.value;

  if (!value) {
    return null;
  }

  if (value.length === 1 || parseFloat(value.slice(1, value.length)) <= 0) {
    return { invalid: true };
  }

  return /^\$[0-9]*(\.[0-9]{1,2})?$/.test(value) ? null : { pattern: true };
}

const SelectOptionsTranslKeys = {
  businessOppr: 'form.businessOppr',
  orderingSupp: 'form.orderingSupp',
  productSupp: 'form.productSupp',
  websiteSupp: 'form.websiteSupp',
  partnerStores: 'form.partnerStores',
};
const DELIMITER = '.';

interface TopicData {
  topicSelected: boolean;
  options: string[];
  templateRef: TemplateRef<any>;
  id: string;
}

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'main-container',
  },
})
export class MainPageComponent implements OnInit, OnDestroy {
  @ViewChild('otherTopicOptions') otherTopicOptionsTemplate: TemplateRef<any>;
  @ViewChild('partnerStoresTopic') partnerStoresTopicTemplate: TemplateRef<any>;
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

  get emailInput(): FormControl {
    return this.customerResponse.get('email') as FormControl;
  }

  customerResponse: FormGroup;

  selectOptions = Object.values(SelectOptionsTranslKeys);
  pastPeriod = false;
  detailsControl: FormControl;
  selectControl: FormControl;
  saleAmtControl: FormControl;

  phoneErrors = {
    required: 'form.phoneNumErrorEnterNumber',
    pattern: 'form.phoneNumError',
  };

  private specificTopicOptions: { [ key in keyof typeof SelectOptionsTranslKeys ]?: string[] } = {
    [ SelectOptionsTranslKeys.businessOppr ]: [
      'form.businessOpprRadio1',
      'form.businessOpprRadio2',
      'form.businessOpprRadio3',
      'form.businessOpprRadio4',
    ],
    [ SelectOptionsTranslKeys.orderingSupp ]: [
      'form.orderingSuppRadio1',
      'form.orderingSuppRadio2',
      'form.orderingSuppRadio3',
    ],
    [ SelectOptionsTranslKeys.productSupp ]: [
      'form.productSuppRadio1',
      'form.productSuppRadio2',
      'form.productSuppRadio3',
      'form.productSuppRadio4',
    ],
    [ SelectOptionsTranslKeys.websiteSupp ]: [
      'form.websiteSuppRadio1',
      'form.websiteSuppRadio2',
      'form.websiteSuppRadio3',
    ],
  };
  private yesNoInputSubscription = Subscription.EMPTY;
  private topicOptionsSectionData$$ = new BehaviorSubject<TopicData>({
    topicSelected: false,
    options: null,
    templateRef: null,
    id: null,
  });
  private shouldDisplayPartnerStoreAdditionalData$$ = new BehaviorSubject<boolean>(false);
  shouldDisplayPartnerStoreAdditionalData$ = this.shouldDisplayPartnerStoreAdditionalData$$.asObservable();
  topicOptionsSectionData$ = this.topicOptionsSectionData$$.asObservable();
  displayIboNumInput: boolean;

  netSalePostValueTransformer = (value: string) => {
    const valueWithoutDollar = value?.slice(1, value.length);

    if (!valueWithoutDollar) {
      return value;
    }

    const currencySign = value[ 0 ];
    const [ valueBeforeDelimiter, valueAfterDelimiter ] = valueWithoutDollar.split(DELIMITER);
    const validatedBeforeDelimiterValue = valueBeforeDelimiter === '0'
      ? valueBeforeDelimiter
      : parseFloat(valueBeforeDelimiter);
    const validatedAfterDelimiterValue = valueAfterDelimiter ?? '';
    const validatedDelimiter = valueWithoutDollar.includes(DELIMITER) ? DELIMITER : '';

    return [
      currencySign,
      validatedBeforeDelimiterValue,
      validatedDelimiter,
      validatedAfterDelimiterValue,
    ].join('');
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appEmailService: AppEmailService,
    private translateService: TranslateService,
    private iboNumHolder: AmwCommonModule,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.toastService.setContainer(this.toastContainer);
    this.customerResponse = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [ Validators.required, Validators.email ]),
      phone: new FormControl('', Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')),
      select: new FormControl('', Validators.required),
      details: new FormControl('', [
        Validators.required,
        Validators.maxLength(10000),
      ]),
    });

    this.detailsControl = this.customerResponse.get('details') as FormControl;
    this.selectControl = this.customerResponse.get('select') as FormControl;

    this.route.data.pipe(
      tap((data: any) => {
        this.displayIboNumInput = data.displayIboNumInput;

        if (data.displayIboNumInput) {
          this.customerResponse.addControl('iboNum', new FormControl(this.iboNumHolder.iboNumber?.toString(), [
            Validators.required,
            Validators.pattern('[0-9]+'),
          ]));
        }
      }),
      take(1),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.yesNoInputSubscription.unsubscribe();
  }

  submitForm(): void {
    if (!this.customerResponse.valid) {
      this.customerResponse.markAllAsTouched();

      return;
    }

    this.parseBodyPart();
    this.sendEmail();
  }

  // convert price value to expected one with two digits after delimiter
  parseValueAndUpdate(): void {
    const valueWithoutDollar = this.saleAmtControl.value?.slice(1, this.saleAmtControl.value.length);

    if (!valueWithoutDollar) {
      return;
    }

    const [ valueBeforeDelimiter, valueAfterDelimiter = '' ] = this.saleAmtControl.value.split(DELIMITER);
    const validatedValueAfterDelimiter = valueAfterDelimiter.length === 2
      ? valueAfterDelimiter
      : valueAfterDelimiter.padEnd(2, '0');

    this.saleAmtControl.setValue([
      valueBeforeDelimiter,
      DELIMITER,
      validatedValueAfterDelimiter,
    ].join(''));
  }

  private parseBodyPart(): void {
    const emailBody: EmailBody = [
      'iboNum',
      'firstName',
      'lastName',
      'email',
      'phoneNum',
      'details',
    ].reduce((body: EmailBody, controlName: string) => {
      const control = this.customerResponse.get(controlName);

      if (control) {
        body[ controlName ] = control.value;
      }

      return body;
    }, {} as EmailBody);

    emailBody.topic = this.translateService.instant(this.selectControl.value);

    if (this.customerResponse.contains('otherTopic')) {
      emailBody.issue = this.customerResponse.get('otherTopic').value;
    } else {
      const partnerStoresTopicControl = this.customerResponse.get('partnerStoresTopic');
      const requestRegardingPreviousPurchaseControl = partnerStoresTopicControl.get('requestRegardingPreviousPurchase');
      const isRequestRegardingPreviousPurchase = requestRegardingPreviousPurchaseControl.value === 'true';

      emailBody.partnerStore = partnerStoresTopicControl.get('storeName').value;
      emailBody.previousPurchase = this.translateService.instant(isRequestRegardingPreviousPurchase ? 'form.partnerStoresYes' : 'form.partnerStoresNo');

      if (isRequestRegardingPreviousPurchase) {
        emailBody.purchaseDate = partnerStoresTopicControl.get('purchaseDate').value;
        emailBody.saleAmount = partnerStoresTopicControl.get('saleAmt').value;
      }
    }

    this.appEmailService.inputContent(emailBody);
  }

  private sendEmail(): void {
    this.appEmailService.sendEmail()
      .pipe(
        tap(data => {
          if (data === 200) {
            this.appEmailService.clearMessage();
            this.router.navigate(['/submit']);
          }
          else if (data === null) {
            this.toastService.trigger(ToastType.error, {title: 'emailSubmitError'});
          }
        })
      )
      .subscribe();
  }

  onSelectOption(selectedOptionTranslationKey: string): void {
    if (this.topicOptionsSectionData$$.getValue().id === selectedOptionTranslationKey) {
      return;
    }

    this.yesNoInputSubscription.unsubscribe();

    if (selectedOptionTranslationKey === SelectOptionsTranslKeys.partnerStores && !this.customerResponse.contains('partnerStoresTopic')) {
      const requestRegardingPreviousPurchase = new FormControl('true', Validators.required);
      const partnerStoresFormGroup = new FormGroup({
        requestRegardingPreviousPurchase,
        storeName: new FormControl('', Validators.required),
      });

      this.yesNoInputSubscription = requestRegardingPreviousPurchase.valueChanges.pipe(
        startWith(requestRegardingPreviousPurchase.value),
        distinctUntilChanged(),
        tap((isRequestRegardingPreviousPurchaseString: string) => {
          const isRequestRegardingPreviousPurchase = isRequestRegardingPreviousPurchaseString === 'true';
          const controlNames = [
            'saleAmt',
            'purchaseDate',
          ];

          if (isRequestRegardingPreviousPurchase) {
            const validators = [
              [ Validators.required, validateSaleAmtControl ],
              [ Validators.required, validateDateControl ]
            ];

            controlNames.forEach((controlName: string, index: number) => {
              partnerStoresFormGroup.addControl(controlName, new FormControl('', validators[ index ]));
            });
          } else {
            controlNames.forEach((controlName: string) => {
              partnerStoresFormGroup.removeControl(controlName);
            });
          }

          this.saleAmtControl = partnerStoresFormGroup.get('saleAmt') as FormControl;
          this.shouldDisplayPartnerStoreAdditionalData$$.next(isRequestRegardingPreviousPurchase);
        }),
      ).subscribe();

      this.customerResponse.addControl('partnerStoresTopic', partnerStoresFormGroup);
      this.customerResponse.removeControl('otherTopic');

      this.topicOptionsSectionData$$.next({
        options: null,
        templateRef: this.partnerStoresTopicTemplate,
        topicSelected: true,
        id: selectedOptionTranslationKey,
      });

      return;
    }

    if (selectedOptionTranslationKey !== SelectOptionsTranslKeys.partnerStores && !this.customerResponse.contains('otherTopic')) {
      this.customerResponse.addControl('otherTopic', new FormControl(null, Validators.required));
      this.customerResponse.removeControl('partnerStoresTopic');
    }

    if (selectedOptionTranslationKey !== SelectOptionsTranslKeys.partnerStores) {
      const options = this.specificTopicOptions[ selectedOptionTranslationKey ];

      this.customerResponse.get('otherTopic').setValue(this.translateService.instant(options[ 0 ]));
      this.topicOptionsSectionData$$.next({
        options,
        templateRef: this.otherTopicOptionsTemplate,
        topicSelected: true,
        id: selectedOptionTranslationKey,
      });
    }
  }
}

import { Directive, HostListener, ElementRef, OnDestroy, AfterViewInit, Optional, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appControlValidity]',
  exportAs: 'appControlValidity'
})
export class ControlValidityDirective implements AfterViewInit, OnDestroy {
  @Input() ariaDescribedbyErrorId: string;

  private control: AbstractControl;
  private destroy$$ = new Subject<void>();
  private controlError$$ = new BehaviorSubject<string>(null);
  controlError$ = this.controlError$$.asObservable();

  interactiveElements: HTMLElement[];
  checkIfControlInvalid = (control: AbstractControl) => control.invalid && (control.touched || control.dirty);

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    @Optional() private parentForm: FormGroupDirective,
    @Optional() private ngForm: NgForm,
    private ngControl: NgControl,
  ) { }

  ngAfterViewInit(): void {
    this.control = this.ngControl.control;

    this.updateControlError(this.checkIfControlInvalid(this.control));

    const tagName = this.elementRef.nativeElement.tagName.toLowerCase();

    this.interactiveElements = tagName === 'input' || tagName === 'textarea' || this.elementRef.nativeElement.getAttribute('tabindex') === '0'
      ? [ this.elementRef.nativeElement ]
      : Array.from(this.elementRef.nativeElement.querySelectorAll('input'));

    merge(
      this.control.statusChanges,
      (this.parentForm ?? this.ngForm).ngSubmit,
    ).pipe(
      tap(() => this.onChange()),
      takeUntil(this.destroy$$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  @HostListener('blur')
  @HostListener('keyup')
  onChange() {
    const isControlInvalid = this.checkIfControlInvalid(this.control);

    this.updateControlError(isControlInvalid);

    isControlInvalid ? this.setInvalidAttributes() : this.removeInvalidAttributes();
  }

  private updateControlError(isControlInvalid: boolean): void {
    this.controlError$$.next(isControlInvalid ? Object.keys(this.control.errors)[ 0 ] : null);
  }

  private setInvalidAttributes(): void {
    this.interactiveElements.forEach((element: HTMLElement) => {
      element.classList.add('invalid-input');
      element.setAttribute('aria-invalid', 'true');

      // for now we have only one element we wanna to announce - error message
      // if it's changed it has to be rewritten, since we have to keep the previous value of
      // 'aria-describedby' attribute
      if (this.ariaDescribedbyErrorId) {
        element.setAttribute('aria-describedby', this.ariaDescribedbyErrorId);
      }
    });
  }

  private removeInvalidAttributes(): void {
    this.interactiveElements.forEach((element: HTMLElement) => {
      element.classList.remove('invalid-input');
      element.setAttribute('aria-invalid', 'false');

      if (element.hasAttribute('aria-describedby')) {
        element.removeAttribute('aria-describedby');
      }
    });
  }
}

<h1 class="main-container-heading-title">
  {{ 'title' | translate }}
</h1>

<div class="main-container-heading-description">
  <p>
    {{ 'submit.description' | translate }}
  </p>
  <div class="main-container-heading-description-subheading">
    <p>{{ 'submit.hours' | translate }}</p>
    <p *ngFor="let workingHoursSection of workingHoursSections">
      {{ workingHoursSection | translate }}
    </p>
  </div>
</div>

<div class="main-container-heading-return">
  <button
    type="button"
    class="submit-button"
    id="return-to-help-center-button"
    (click)="redirectToHelpCenter()"
  >
    {{ 'submit.return' | translate }}
  </button>
</div>

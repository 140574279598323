import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { ElementRef, QueryList } from '@angular/core';

import { fromEvent, Observable, Subscriber } from 'rxjs';
import { finalize, share, take } from 'rxjs/operators';

import { get } from 'lodash';


import { fromEventOutsideZone } from './rxjs.utils';

const FOCUSABLE_ELEMENTS = new Set<string>([
  'a',
  'area',
  'input',
  'select',
  'textarea',
  'button',
  'iframe',
]);

export interface AnimateElementOptions {
  start: string;
  end: string;
  transition: string;
  propertyToAnimate: string;
}

export enum Events {
  keydown = 'keydown',
  keyup = 'keyup',
  click = 'click',
  focus = 'focus',
  blur = 'blur',
  focusin = 'focusin',
  focusout = 'focusout',
  mouseup = 'mouseup',
  mouseenter = 'mouseenter',
  mouseleave = 'mouseleave',
  mousedown = 'mousedown',
  resize = 'resize',
  scroll = 'scroll',
  touchstart = 'touchstart',
  touchend = 'touchend',
  orientationchange = 'orientationchange',
}

export enum Keys {
  shift = 'shift',
  tab = 'tab',
  escape = 'escape',
  delete = 'delete',
  enter = 'enter',
  arrowDown = 'arrowdown',
  arrowUp = 'arrowup',
  space = 'space',
}

export const optionsWithPassiveProperty = normalizePassiveListenerOptions({
  passive: true,
}) as EventListenerOptions;

export const documentFocusEventOutsideAngular$: Observable<FocusEvent> = fromEventOutsideZone<FocusEvent>(
  document,
  Events.focusin,
  optionsWithPassiveProperty,
).pipe(share());

export const documentKeyPressedEventOutsideAngular$: Observable<KeyboardEvent> = fromEventOutsideZone<KeyboardEvent>(
  document,
  Events.keydown,
).pipe(share());

/**
 * @param {HTMLElement} element
 * @param {boolean} onlyByKeyboard
 * @returns {boolean}
 */
export function isElementFocusable(element: HTMLElement, onlyByKeyboard: boolean = true): boolean {
  const isDisabled = element.hasAttribute('disabled');
  let isElementForbiddenToBeFocused = isElementFocusingForbiddenByTabindex(element);
  const hasTabindex = isElementForbiddenToBeFocused !== null;

  if (!onlyByKeyboard) {
    isElementForbiddenToBeFocused = hasTabindex ? false : isElementForbiddenToBeFocused;
  }

  if (isDisabled || isElementForbiddenToBeFocused) {
    return false;
  }

  if (hasTabindex) {
    return true;
  }

  return isElementFromAllowedFocusableSet(element);
}

/**
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function isElementFromAllowedFocusableSet(element: HTMLElement): boolean {
  const tagName = element.tagName.toLowerCase();

  return FOCUSABLE_ELEMENTS.has(tagName);
}

/**
 * @param {HTMLElement} element
 * @returns {boolean|null}
 */
function isElementFocusingForbiddenByTabindex(element: HTMLElement): boolean | null {
  const tabindex = element.getAttribute('tabindex');

  return tabindex ? +tabindex <= -1 : null;
}

/**
 * @param {KeyboardEvent} event
 * @return {boolean}
 */
export function isTabKey({ code = '' }: KeyboardEvent): boolean {
  return code.toLowerCase() === Keys.tab;
}

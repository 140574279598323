import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AppEmailService } from '../services';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

import {
  onlyRegularCharacters,
  onlyRegularCharactersAndSlashHyphenDot,
  onlyRegularCharactersAndDash,
  onlyDigits,
  onlyLetters,
  RegularCharactersValidatorName,
} from '../form-validators/pattern.validator';
import { tap } from 'rxjs/operators';
import { ToastService } from '@app/shared/toast-grouped/services';
import { ToastContainerDirective } from 'ngx-toastr';
import { ToastType } from '@app/shared/toast-grouped/models';


@Component({
  selector: 'app-ribbon-page',
  templateUrl: './ribbon-page.component.html',
  styleUrls: ['./ribbon-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'main-container',
  },
})
export class RibbonPageComponent implements OnInit {

  constructor(
    private router: Router,
    private appEmailService: AppEmailService,
    private toastService: ToastService,
  ) {}
  phoneErrors = {
    required: 'form.phoneNumErrorEnterNumber',
    pattern: 'form.phoneNumError',
  };
  streetAddressErrors = {
    required: 'form.streetAddressError',
    [ RegularCharactersValidatorName.regularCharactersAndSlashHyphenDot ]: 'form.regularCharactersAndSlashHyphenDot',
  };
  cityErrors = {
    required: 'ribbon.cityRequiredError',
    [ RegularCharactersValidatorName.regularCharactersAndSlashHyphenDot ]: 'form.regularCharactersAndSlashHyphenDot',
  };
  certificateErrors = {
    required: 'ribbon.certificateRequiedError',
    [ RegularCharactersValidatorName.regularCharactersAndDash ]: 'form.onlyRegularCharactersAndDash',
  };
  stateErrors: any;
  zipErrors: any;
  stateLabelKey: string;
  zipLableKey: string;
  placeholderValues: { city: string; state: string; zip: string; };
  customerResponse: FormGroup;
  certificatesFormArray: FormArray;
  emailInput: FormControl;

  private certeficateNumInputValidators = [ Validators.required, onlyRegularCharactersAndDash() ];
  certificateControl: () => FormControl = () => new FormControl('', this.certeficateNumInputValidators);
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

  ngOnInit(): void {
    const isUserUS = environment.country === 'US';

    if (isUserUS) {
      this.stateLabelKey = 'ribbon.state';
      this.zipLableKey = 'ribbon.zip';
      this.zipErrors = {
        required: 'ribbon.zipRequiredError',
        [ RegularCharactersValidatorName.onlyDigits ]: 'form.onlyDigits',
      };
      this.stateErrors = {
        required: 'ribbon.stateRequiredError',
        [ RegularCharactersValidatorName.onlyLetters ]: 'form.onlyLetters',
      };
      this.placeholderValues = { city: 'Ada', state: 'Michigan', zip: '49355' };
    } else {
      this.stateLabelKey = 'ribbon.province';
      this.zipLableKey = 'ribbon.postal';
      this.zipErrors = {
        required: 'ribbon.postalRequiredError',
        [ RegularCharactersValidatorName.regularCharacters ]: 'form.onlyRegularCharacters',
      };
      this.stateErrors = {
        required: 'ribbon.provinceRequiredError',
        [ RegularCharactersValidatorName.onlyLetters ]: 'form.onlyLetters',
      };
      this.placeholderValues = { city: 'London', state: 'Ontario', zip: 'N6E2Z3' };
    }

    const addressLineAndCityInputValidators = [ Validators.required, onlyRegularCharactersAndSlashHyphenDot() ];

    // FORM CONTROL VARIABLES FOR EACH INPUT
    this.customerResponse = new FormGroup({
      iboNum: new FormControl(''),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [ Validators.required, Validators.email ]),
      phoneNum: new FormControl('', [ Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}') ]),
      certificateNumbers: new FormArray([]),
      certificateName: new FormControl(''),
      city: new FormControl('', addressLineAndCityInputValidators),
      state: new FormControl('', [ Validators.required, onlyLetters() ]),
      zip: new FormControl('', [ Validators.required, isUserUS ? onlyDigits() : onlyRegularCharacters() ]),
      streetAddress: new FormControl('', addressLineAndCityInputValidators),
    });
    this.emailInput = this.customerResponse.get('email') as FormControl;
    this.certificatesFormArray = this.customerResponse.get('certificateNumbers') as FormArray;
    this.toastService.setContainer(this.toastContainer);
  }

  submitForm(): void {
    if (!this.customerResponse.valid) {
      this.customerResponse.markAllAsTouched();

      return;
    }

    this.appEmailService.inputContent(this.customerResponse.value);
    this.sendEmail();
  }

  private sendEmail(): void {
    this.appEmailService.sendEmail()
      .pipe(
        tap(data => {
          if (data === 200) {
            this.appEmailService.clearMessage();
            this.router.navigate(['/submit']);
          }
          else if (data === null) {
            this.toastService.trigger(ToastType.error, {title: 'emailSubmitError'});
          }
        })
      )
      .subscribe();
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isHybrisApiCallWithSession } from '@app/models-rules/hybris.models-rules';
import { HybrisSessionService } from '@app/services/hybris-session.service';

import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private hybrisSessionService: HybrisSessionService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isHybrisApiCallWithSession(req.url)) {
      return this.extendRequestWithAuthHeaders(req, next).pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 401:
              return this.hybrisSessionService.obtainSession().pipe(
                switchMap(() => this.extendRequestWithAuthHeaders(req, next)),
              );
            default:
              return throwError(error);
          }
        }),
      );
    }

    return next.handle(req);
  }

  private extendRequestWithAuthHeaders(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.hybrisSessionService.sessionData$$.asObservable().pipe(
      first(({ isLoading }) => !isLoading),
      switchMap(({ data: { securityToken: { idToken, accessToken, tokenType } } }) => {
        const cloneReq = req.clone({
          setHeaders: {
            authorization: `${ tokenType } ${ accessToken }`,
            id_token: idToken,
          },
        });

        return next.handle(cloneReq);
      })
    );
  }
}

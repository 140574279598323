import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AppTranslateService } from '@app/services';
import { HybrisCmsDataService } from '@app/services/hybris-cms-data.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  siteLogoSlot$ = this.hybrisCmsDataService.pageData$$.asObservable().pipe(
    map(({ isLoading, data }) => ({
      isLoading,
      data: data ? data.slots.SiteLogoSlot[ 0 ] : null,
    })),
  );
  backButtonUrl$ = this.appTranslateService.currentLocale$.pipe(
    map(() => `${ environment.hybrisOrigin }/${ this.appTranslateService.getLanguageString() }`),
  );
  siteName = this.getSiteName();
  translationsLoading$ = this.appTranslateService.translationsLoading$;

  constructor(
    private hybrisCmsDataService: HybrisCmsDataService,
    private appTranslateService: AppTranslateService,
  ) {}

  private getSiteName(): string {
    const [ _, domain ] = environment.hybrisOrigin.split('amway');

    return `Amway${ domain }`;
  }
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isHybrisApiCallWithSession } from '@app/models-rules/hybris.models-rules';
import { AppTranslateService } from '@app/services';

import { Observable } from 'rxjs';

@Injectable()
export class QueryParamsInterceptor implements HttpInterceptor {
  constructor(
    private appTranslateService: AppTranslateService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isHybrisApiCallWithSession(req.url)) {
      const locale = this.appTranslateService.getLanguageString();
      const cloneReq = req.clone({
        params: req.params.set('lang', locale),
      });

      return next.handle(cloneReq);
    }

    return next.handle(req);
  }
}

import { Country } from '@app/models/country.model';

export const environment = {
  production: true,
  country: Country.CA,
  hybrisOrigin: 'https://www.amway.ca',
  siteId: 'lynx-ca',
};

export const EMAIL_CONFIG = {
  to: 'customer.service.ca@amway.com',
};

export const AUTH_CONFIG = {
  host: 'prodapi.apigtwy.amer.amway.net',
  port: 443,
};

<!-- NOTE: we have to render these components, since inside div.container we use styles from these components -->
<!-- and until angular renders them - styles will not be added to the document -->
<div class="hidden">
  <amer-footer></amer-footer>
  <amer-footer-copyright></amer-footer-copyright>
  <amer-footer-certification-container></amer-footer-certification-container>
</div>

<div class="container">
  <div class="amway-footer-copyrights">
    <div class="amway-footer-copyrights__content">
      <div class="amway-footer-copyrights__copyright skeleton-loader" [ngStyle]="copyrightText"></div>

      <ul class="amw-list-unstyled amway-footer-copyrights__links">
        <li *ngFor="let link of links">
          <div class="amer-link">
            <div class="skeleton-loader" [ngStyle]="link.text"></div>
            <span [ngStyle]="link.divider"></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="amw-footer-logos">
    <div class="amw-footer-logos__content">
      <ul class="amw-footer-logos__list">
        <li *ngFor="let logo of logos" class="amw-footer-logos__list-item skeleton-loader" [ngStyle]="logo"></li>
      </ul>
    </div>
  </div>
</div>
